<div class="card">
    <div class="card-body">
        <ngx-spinner name="dialogSpinner" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p style="color: white">Loading ...</p>
        </ngx-spinner>        
        <div class="row">
            <div class="col-md-12">
                <h3>A3 Media audience parameters</h3>
                <section>
                    <mat-button-toggle-group [(ngModel)]="newAudience" name="newAudienceSelector" aria-label="New audience" (change)="onNewAudienceSelectedChange()">
                        <mat-button-toggle value="0">Existing audience</mat-button-toggle>
                        <mat-button-toggle value="1">New audience</mat-button-toggle>
                    </mat-button-toggle-group>        
                </section>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-12" *ngIf="newAudience == '0'">
                    <mat-form-field *ngIf="audiences.length > 0" style="width: 100%;">
                        <mat-label>Audience</mat-label>
                        <input #matAutocompleteAudiences type="text" placeholder="Pick one" aria-label="Audience" matInput 
                        [formControl]="form.controls.audienceName" [matAutocomplete]="auto"
                        (input)="filterAudiences()" (focus)="filterAudiences()" (click)="resetAudiencesFilter()"
                        style="width: 100%;">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onAudienceChange($event)">
                            <mat-option *ngFor="let audience of filteredAudiences" [value]="audience">{{audience}}</mat-option>
                        </mat-autocomplete>                      
                        <mat-error *ngIf="form.controls.audienceName.errors && (form.controls.audienceName.dirty || form.controls.audienceName.touched)">Existing audience is required</mat-error>
                    </mat-form-field>   
                    <mat-label *ngIf="audiences.length == 0" style="width: 100%;">There are no audiences yet</mat-label>
                </div>
                <div class="col-md-12" *ngIf="newAudience == '1'">
                    <mat-form-field style="width: 100%;">
                        <mat-label>New audience name</mat-label>
                        <input matInput [formControl]="form.controls.audienceName" placeholder="Ex. PID_..." (input)="onAudienceDescriptionChange()">
                        <mat-error *ngIf="form.controls.audienceName.errors && (form.controls.audienceName.dirty || form.controls.audienceName.touched)">Audience name is required</mat-error>
                    </mat-form-field>   
                </div> 
            </div>      
        </form>
    </div>
</div>
