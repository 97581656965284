import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NgxSpinnerService } from 'ngx-spinner';
import { WamCustomSegment } from 'src/app/models/services/wamCustomSegment';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';

@Component({
  selector: 'app-movistar-attributes',
  templateUrl: './movistar-attributes.component.html',
  styleUrls: ['./movistar-attributes.component.sass']
})
export class MovistarAttributesComponent implements OnInit {
  @Input() 
  form: FormGroup;

  @Input() 
  updateValueAndValidity: () => void;

  constructor() {
  }

  ngOnInit(): void {
  }

  onAudienceNameChange = (): void => {
    this.updateValueAndValidity();
  }
  
}
