<div *ngIf="loadFinished && usersService.hasGlobalSectionRight('Admin')" style="position:relative;left:30px;width: 95%;">
    <div class="row">&nbsp;</div>
    <h3>Portal running processes</h3>
    <div class="row">
        <div class="card mb-3 widget-content bg-premium-dark" style="margin:5px; width: 32.5%;" *ngFor="let manualProcessInfo of manualProcessesInfo">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left"  style="width: 100% !important">
                    <div class="widget-heading">{{manualProcessInfo.name}}</div>
                </div>
                <div class="widget-content-right" style="margin-left:10px; text-align: center;">
                    <div class="widget-numbers text-warning">
                        <span>{{manualProcessInfo.runningProcesses | number:'':'es'}}</span>
                    </div>
                </div>
            </div>
        </div>    
    </div>
    <br/>
    <div class="row" *ngIf="audiencesDataSource.data.length > 0">
        <div class="col-md-12">
            <h3>Audiences running, failed or with files running or failed</h3>
            <app-publicis-table [dataSource]="audiencesDataSource" #parent>
                <ng-container *ngFor="let audience of parent.data; index as i">   
                    <tr>
                        <th scope="row" class="align-middle" style="width: 5%;">{{ audience.id }}</th>
                        <td class="align-middle" style="width: 10%">
                            <img width="48" [src]="companiesService.getCompanyLogoSrc(audience.company.id)" />
                        </td>
                        <td class="align-middle" style="width: 45%; word-break: break-all;">{{ audience.name }}</td>
                        <td class="align-middle" style="width: 7%;">{{ audience.created | date }}</td>
                        <td class="align-middle" style="width: 7%;">{{ audience.updated | date }}</td>
                        <td class="align-middle" style="width: 7%;">{{ audience.profilesCount | number:'':'es' }}</td>                        
                        <td class="align-middle" style="width: 4%;">
                            <div>
                                <div class="font-icon-wrapper-button" (click)="showAudienceDetails(audience)" style="cursor:pointer;display:inline-flex;width:44px;height:44px; ">
                                    <i class="pe-7s-info"></i>
                                    <i class="pe-7s-ticket" style="font-size:1.2em;position:relative;left:-10px;top:20px" *ngIf="!!audience.tags && audience.tags.length > 0"></i>
                                </div>
                            </div>
                        </td>
                        <td class="align-middle" style="width: 10%;">
                            <div>
                                <div class="font-icon-wrapper">
                                    <i [class]="getStatusStyle(audience)"></i>
                                </div>
                                <div class="d-flex justify-content-center" style="text-align:center">
                                    {{ getStatusLabel(audience) }}
                                </div>
                            </div>
                        </td>                                     
                        <td class="align-middle" style="width: 5%;">
                            <div>
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="refreshAudience(audience)">
                                        <mat-icon class="pe-7s-refresh-2"></mat-icon>
                                        <span>Refresh</span>
                                    </button>
                                    <mat-divider></mat-divider>  
                                    <button mat-menu-item (click)="deleteAudience(audience)">
                                        <mat-icon class="pe-7s-trash"></mat-icon>
                                        <span>Delete audience</span>
                                    </button>
                                </mat-menu>
                            </div>                  
                        </td>               
                    </tr>            
                    <tr *ngIf="audience?.files?.length > 0">
                        <th scope="row" colspan="1"></th>
                        <td colspan="10">
                            <table class="table table-striped">
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col">Creation date</th>
                                    <th scope="col">Attributes</th>
                                    <th scope="col">Size</th>
                                    <th scope="col">Times sent</th>
                                    <th scope="col">Last sent date</th>
                                    <th scope="col">Status</th>
                                    <th scope="col"></th>
                                </tr>
                                <tr *ngFor="let audienceFile of audience.files; index as j">
                                    <th class="align-middle" style="width: 10%;">{{ audienceFile.id }}</th>
                                    <td class="align-middle" style="width: 5%;"><img width="32px" height="32px" [src]="getAudienceFileIcon(audienceFile)" /></td>
                                    <td class="align-middle" style="width: 10%;">{{ audienceFile.created | date }}</td>
                                    <td class="align-middle" style="width: 35%; word-break: break-all;">
                                        <ng-container *ngFor="let attribute of audienceFile.fileAttributes">
                                            <span *ngIf="!!attribute.visible">{{ audienceFileAttributesDefinition[attribute.attributeId] }} : {{ attribute.value }}</span><br/>
                                        </ng-container>
                                    </td>
                                    <td class="align-middle" style="width: 7%;">{{ audienceFile.bytesSize | bytes }}</td>
                                    <td class="align-middle" style="width: 3%;">{{ audienceFile.timesSent.length }}</td>
                                    <td class="align-middle" style="width: 10%;">{{ audienceFile.timesSent.length > 0 ? (audienceFile.timesSent[audienceFile.timesSent.length - 1].timeSent | date:'medium') : '' }}</td>
                                    <td class="align-middle" style="width: 5%;">
                                        <div>
                                            <div class="font-icon-wrapper">
                                                <i [class]="getFileStatusStyle(audienceFile)"></i>
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                {{ getFileStatusLabel(audienceFile) }}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="align-middle" style="width: 15%;">
                                        <div class="btn" style="padding: 0.1rem 0.1rem" title="Delete audience file">
                                            <div class="font-icon-wrapper-medium" (click)="deleteAudienceFile(audience, audienceFile)">
                                                <i class="pe-7s-trash"></i>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </ng-container>
            </app-publicis-table>
        </div>        
    </div>
</div>