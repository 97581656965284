import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timezoneDate'
})
export class TimezoneDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: string | Date, format: string = 'EEEE, d \'de\' MMMM \'de\' yyyy HH:mm', locale: string = 'es'): string | null {
    if (!value) return null;

    let date = new Date(value);
    const timeZoneOffset = new Date().getTimezoneOffset(); // Offset en minutos

    // Ajustar la fecha restando el offset de la zona horaria
    date = new Date(date.getTime() - timeZoneOffset * 60000);

    return this.datePipe.transform(date, format, '', locale);
  }
}
