import { Component, OnDestroy, OnInit } from '@angular/core';
import { Audience, AudienceFile, AudienceParameters } from 'src/app/models/services/audience';
import { AudiencesStatus } from 'src/app/models/services/audiencesStatus.enum';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';
import { DialogService } from 'src/app/services/core/dialog.service';
import { timer, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { AudiencesFilesStatus } from 'src/app/models/services/audiencesFilesStatus.enum';
import { AudiencesFilesTargetPlatforms } from 'src/app/models/services/audiencesFilesTargetPlatforms.enum';
import { AudiencesFilesAttributes } from 'src/app/models/services/audiencesFilesAttributes.enum';
import { TargetPlatformSelectorDialogService } from 'src/app/services/dialogs/target-platform-selector-dialog.service';
import { PublicisTableDataSource } from 'src/app/models/components/publicis-table';
import { AudienceParametersDialogService } from 'src/app/services/dialogs/audience-parameters-dialog.service';
import { UpdateAudienceRequest } from 'src/app/models/services/updateAudience';
import { UsersService } from 'src/app/services/users.service';
import { AudienceInfoDialogService } from 'src/app/services/dialogs/audience-info-dialog.service';
import { LookAlikesService } from 'src/app/services/look-alikes.service';
import { LookAlikeSourceTypes } from 'src/app/models/services/lookAlikeSourceTypes.enum';
import { Router } from '@angular/router';
import { Company } from 'src/app/models/services/company';
import { CompanySelectorService } from 'src/app/services/dialogs/company-selector.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { BaseTenantRequiredPageComponent } from 'src/app/components/core/base-tenant-required-page/base-tenant-required-page.component';
import { DialogCustomButtonsService } from 'src/app/services/core/dialog-custom-buttons.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AudienceTagsDialogService } from 'src/app/services/dialogs/audience-tags-dialog.service';
import { Tag } from 'src/app/models/services/tag';
import { AudiencesListFilterDialogService } from 'src/app/services/dialogs/audiences-list-filter-dialog.service';
import { AudiencesListFilter } from 'src/app/models/ui/audiencesListFilter';
import { MenuCloseReason } from '@angular/material/menu/menu';
import { TextAreaFieldDialogComponent } from 'src/app/components/core/text-area-field-dialog/text-area-field-dialog.component';
import { TextAreaFieldDialogService } from 'src/app/services/core/text-area-field-dialog.service';

@Component({
  selector: 'app-audiences-list',
  templateUrl: './audiences-list.component.html',
  styleUrls: ['./audiences-list.component.sass']
})
export class AudiencesListComponent extends BaseTenantRequiredPageComponent implements OnInit, OnDestroy {
  public heading: string = 'Audiences list';
  public subheading: string = '';
  public icon: string = 'pe-7s-radio icon-gradient bg-midnight-bloom';

  refreshTimerSubscription: Subscription;
  activePolling: boolean = true;
  public fullAudiences: Audience[];
  private baseAudiences: Audience[];
  public showArchivedTab: boolean = false;
  public selectedTab: number = 0;
  public filesVisibleAudienceId?: number;  
  public audiencesDataSource: PublicisTableDataSource;

  public audiencesFilter: AudiencesListFilter = {
    textSearch: undefined,
    tagsSearch: []
  };
  
  public allowedCompanies: Company[];
  public tags: Tag[];

  private currentPageIndex: number = 1;
  private currentSortField: string = "id";
  private currentSortDirection: string = "DESC";

  constructor(
    public usersService: UsersService,
    private audiencesService: AudiencesService,
    private lookAlikesService: LookAlikesService,
    private dialogService: DialogService,
    private dialogCustomButtonsService: DialogCustomButtonsService,
    private audienceParametersDialogService: AudienceParametersDialogService,
    private targetPlatformSelectorDialogService: TargetPlatformSelectorDialogService, 
    private audienceInfoDialogService : AudienceInfoDialogService,   
    private audienceTagsDialogService : AudienceTagsDialogService,   
    private audiencesListFilterDialogService : AudiencesListFilterDialogService,   
    private companySelectorService: CompanySelectorService,
    private textAreaFieldDialogService: TextAreaFieldDialogService,
    globalsService: GlobalsService,
    private spinnerService: NgxSpinnerService,
    private router: Router) {

    super(
      usersService.hasSelectedCompanyModuleRight('Audiences', 'AudiencesList'),
      globalsService, 
      router);     
    
    if(this.redirecting)
      return;

    // Add action columns just if proper right is present
    this.audiencesDataSource = {
      tableDefinition:  
        usersService.hasSelectedCompanyActionRight('Audiences', 'AudiencesList', 'Manage') ? 
        [
          {
            headerDescription: 'ID',
            enableSort: true,
            sortFieldName: 'id'
          }, {
            headerDescription: 'Audience',
            enableSort: true,
            sortFieldName: 'name'
          }, {
            headerDescription: 'Created',
            enableSort: true,
            sortFieldName: 'created'      
          }, {
            headerDescription: 'Refreshed',
            enableSort: true,
            sortFieldName: 'updated'      
          }, {
            headerDescription: 'Matched profiles',
            enableSort: true,
            sortFieldName: 'profilesCount'      
          }, {
            headerDescription: 'Matched per type',
            enableSort: false      
          }, {
            headerDescription: '',
            enableSort: false      
          }, {
            headerDescription: 'Status',
            enableSort: true,
            sortFieldName: 'status',
            headerStyle: 'text-align: center;'      
          }, {
            headerDescription: '',
            enableSort: false,
            headerStyle: 'text-align: center;'            
          }, {
            headerDescription: '',
            enableSort: false      
          }    
        ] :
        [
          {
            headerDescription: 'ID',
            enableSort: true,
            sortFieldName: 'id'
          }, {
            headerDescription: 'Audience',
            enableSort: true,
            sortFieldName: 'name'
          }, {
            headerDescription: 'Created',
            enableSort: true,
            sortFieldName: 'created'      
          }, {
            headerDescription: 'Refreshed',
            enableSort: true,
            sortFieldName: 'updated'      
          }, {
            headerDescription: 'Matched profiles',
            enableSort: true,
            sortFieldName: 'profilesCount'      
          }, {
            headerDescription: 'Matched per type',
            enableSort: false      
          }, {
            headerDescription: 'Status',
            enableSort: true,
            sortFieldName: 'status',
            headerStyle: 'text-align: center;'      
          }     
        ],
      data: [],
      enableSort: true,
      enablePagination: true,
      pageSize: 10,
      serverSideConfiguration: {
        totalCount: 0,
        getDataCallback: (pageIndex: number, sortField: string, sortDirection: string): void => {
          this.currentPageIndex = pageIndex;
          this.currentSortField = sortField;
          this.currentSortDirection = sortDirection;
          
          this.loadAudiences(true);
        }
      }
    };
    this.allowedCompanies = globalsService.getAllowedCompanies();
  }

  ngOnInit(): void {
    if(this.redirecting)
      return;

    this.activePolling = true;
    this.loadAudiences();
  }

  ngOnDestroy(): void {
    this.stopRefreshPolling();
  }

  private loadAudiences = (showSpinner?: boolean): void => {
    console.log("Starting audiences load")
    let _this: AudiencesListComponent = this;

    // Show spinner just first time
    if(!this.fullAudiences || !!showSpinner)
      this.spinnerService.show();

    if (!!this.audiencesDataSource.serverSideConfiguration) {
      this.audiencesService.getAudiencesPage({
        pageIndex: this.currentPageIndex,
        pageSize: 10,
        textSearch: this.audiencesFilter.textSearch,
        tagIds: this.audiencesFilter.tagsSearch.map(x => x.id),        
        archived:  this.selectedTab != 0,
        sortField: this.currentSortField,
        sortDirection: this.currentSortDirection
      }).subscribe(
        resp => {
          if (_this.activePolling) {
            _this.fullAudiences = resp.pageContent;
            let cloned: PublicisTableDataSource = { ...this.audiencesDataSource};
            cloned.data = resp.pageContent;
            cloned.serverSideConfiguration.totalCount = this.selectedTab == 0 ? resp.activeAudiencesCount : resp.archivedAudiencesCount;
            this.audiencesDataSource = cloned;        
            _this.showArchivedTab = resp.archivedAudiencesCount > 0;
    
            console.log("Audiences page load success")
    
            _this.startRefreshPolling();
          }
  
          this.spinnerService.hide();
        },
        () => {
          if (!_this.fullAudiences || !!_this.refreshTimerSubscription)
            console.log("Audiences page load error")
  
            this.loadAudiences(false);
        }
      );
    }
    else {
      this.audiencesService.getAudiences().subscribe(
        resp => {
          if (_this.activePolling) {
            _this.fullAudiences = resp;        
            _this.baseAudiences = _this.selectedTab == 0 ? resp.filter(x => x.status != AudiencesStatus.Archived) : resp.filter(x => x.status == AudiencesStatus.Archived);
            _this.showArchivedTab = _this.fullAudiences.length > _this.baseAudiences.length;
            _this.filterAudiences();
    
            console.log("Audiences load success")
    
            _this.startRefreshPolling();
          }
  
          this.spinnerService.hide();
        },
        () => {
          if (!_this.fullAudiences || !!_this.refreshTimerSubscription)
            console.log("Audiences load error")
  
            if (!_this.fullAudiences || _this.activePolling) {
              _this.startRefreshPolling();
    
            this.spinnerService.hide();  
          }
        }
      );
    }

    this.audiencesService.getAllTags().subscribe(x => this.tags = x);
  }

  tabChange = (changeEvent: MatTabChangeEvent): void => {
    this.selectedTab = changeEvent.index;
    if (!!this.audiencesDataSource.serverSideConfiguration) {
      this.loadAudiences(true);
    }
    else {
      switch(changeEvent.index) {
        case 0:
          this.baseAudiences = this.fullAudiences.filter(x => x.status != AudiencesStatus.Archived);
          break;
        case 1:
          this.baseAudiences = this.fullAudiences.filter(x => x.status == AudiencesStatus.Archived);
          break;
        default:
          break;
      }
      
      this.filterAudiences();
    }
  }

  getStatusStyle = (audience: Audience): string => {
    switch (audience?.status) {
      case AudiencesStatus.Created:
        return "pe-7s-check";
      case AudiencesStatus.Error:
        return "pe-7s-attention";
      case AudiencesStatus.Archived:
        return "pe-7s-box1";  
      default:
        return "pe-7s-hourglass fa-pulse";
    };
  }

  getStatusLabel = (audience: Audience): string => {
    switch (audience?.status) {
      case AudiencesStatus.Created:
        return "Done";
      case AudiencesStatus.Error:
        return "Error";
      case AudiencesStatus.CalculatingProfilesCount:
        return "Calculating profiles count";
      case AudiencesStatus.CalculatingProfilesCountPerType:
        return "Calculating profiles count per type";
      case AudiencesStatus.RetrievingProfiles:
        return "Retrieving profiles";
      case AudiencesStatus.CalculatingLookAlikeParameters:
        return "Calculating look-alike parameters";        
      case AudiencesStatus.MovingAudience:
        return "Moving audience";          
      case AudiencesStatus.WaitingRetry:
        return "Processing issue, waiting for retry";            
      case AudiencesStatus.Archived:
        return "Archived";              
      default:
        return `Creating (${audience?.progressPercentage}%)`;
    };
  }

  getFileStatusStyle = (audienceFile: AudienceFile): string => {
    switch (audienceFile?.status) {
      case AudiencesFilesStatus.Creating:
      case AudiencesFilesStatus.Sending:
      case AudiencesFilesStatus.Sharing:
        return "pe-7s-hourglass fa-pulse";
      case AudiencesFilesStatus.CreationError:
      case AudiencesFilesStatus.SentError:
      case AudiencesFilesStatus.SharingError:
        return "pe-7s-attention";
      default:
        return "pe-7s-check";
    }    
  }

  getFileStatusLabel = (audienceFile: AudienceFile): string => {
    switch (audienceFile?.status) {
      case AudiencesFilesStatus.Creating:
        return `Creating (${audienceFile?.progress}%)`;
      case AudiencesFilesStatus.Sending:
        return `Sending (${audienceFile?.progress}%)`;
      case AudiencesFilesStatus.CreationError:
        return "Creation error";
      case AudiencesFilesStatus.SentError:
        return "Sent error";
      case AudiencesFilesStatus.Sharing:
        return "Sharing";
      case AudiencesFilesStatus.SharingError:
        return "Sharing error";
            default:
        return "Done";
    }
  }

  isAudienceFileSendAllowed = (audienceFile: AudienceFile): boolean => {
    return (
      audienceFile.targetPlatform == AudiencesFilesTargetPlatforms.WAMProxy ||
      audienceFile.targetPlatform == AudiencesFilesTargetPlatforms.Meta || (
        this.usersService.hasGlobalSectionRight('Admin') && (
          audienceFile.targetPlatform == AudiencesFilesTargetPlatforms.TapTap ||
          audienceFile.targetPlatform == AudiencesFilesTargetPlatforms.TapTapOOH ||
          audienceFile.targetPlatform == AudiencesFilesTargetPlatforms.Amazon ||
          audienceFile.targetPlatform == AudiencesFilesTargetPlatforms.Movistar ||
          audienceFile.targetPlatform == AudiencesFilesTargetPlatforms.Xandr
        )
      )
    ) && audienceFile.status == AudiencesFilesStatus.Done;
  }

  isAudienceFileDeletionAllowed = (audienceFile: AudienceFile): boolean => {
    return audienceFile.status != AudiencesFilesStatus.Creating && audienceFile.status != AudiencesFilesStatus.Sending;
  }

  updateAudience = (audience: Audience): void => {
    let _this: AudiencesListComponent = this;
    this.audienceParametersDialogService.show(
      (audienceParameters: AudienceParameters) => {
        audience.name = audienceParameters.name;
        var request: UpdateAudienceRequest = {
          name: audienceParameters.name,
          categoryId: audienceParameters.categoryId
        };
        _this.audiencesService.updateAudience(audience.id, request).subscribe(
          () => {
            _this.dialogService.showMessage("Update audience", "Audience updated");
            _this.startRefreshPolling();
          },
          () => { _this.startRefreshPolling(); }
        );
      }, 
      () => { _this.startRefreshPolling(); }, 
      {
        name: audience.name,
        categoryId: audience.category.id            
      }
    );
  }

  refreshAudience = (audience: Audience): void => {
    let _this: AudiencesListComponent = this;
    this.dialogService.showConfirmation(
      audience.status == AudiencesStatus.Archived ?
      `Are you sure to rehydrate "${audience.name}"?` :
      `Are you sure to refresh "${audience.name}"? The look-alikes information related with the audience will be removed.`,
      () => {
        _this.spinnerService.show();
        audience.status = AudiencesStatus.CalculatingProfilesCount;
        _this.audiencesService.refreshAudience(audience.id).subscribe(
          () => {
            _this.spinnerService.hide();
            if (audience.status == AudiencesStatus.Archived)
              _this.dialogService.showMessage("Rehydrate audience", "Audience rehydrate process launched");
            else
              _this.dialogService.showMessage("Refresh audience", "Audience refresh process launched");
            _this.startRefreshPolling();
          }, () => {
            _this.spinnerService.hide();
            _this.startRefreshPolling();
          });    
      },
      () => { _this.startRefreshPolling(); });    
  }

  sendAudience = (audience: Audience): void => {
    let _this: AudiencesListComponent = this;
    this.targetPlatformSelectorDialogService.show(
      audience,
      true,
      (audienceFile: AudienceFile) => {
        _this.spinnerService.show();
        _this.audiencesService.sendAudience(audience.id, audienceFile).subscribe(
          () => {
            _this.spinnerService.hide();
            _this.dialogService.showMessage("Send audience", "Audience sent");
            _this.startRefreshPolling();
          },
          () => { 
            _this.spinnerService.hide();
            _this.startRefreshPolling(); 
          });
      },
      () => { _this.startRefreshPolling(); }
    );
  }

  createAudienceFile = (audience: Audience): void => {
    let _this: AudiencesListComponent = this;
    this.targetPlatformSelectorDialogService.show(
      audience,
      false,
      (audienceFile: AudienceFile) => {
        _this.spinnerService.show();
        _this.audiencesService.createAudienceFile(audience.id, audienceFile).subscribe(
          () => {
            _this.spinnerService.hide();
            _this.dialogService.showMessage("Create audience file", "Audience file created");
            _this.startRefreshPolling();
          },
          () => { 
            _this.spinnerService.hide();
            _this.startRefreshPolling();
          });
      },
      () => { _this.startRefreshPolling(); }
    );
  }

  downloadAudienceFile = (audience: Audience, audienceFile: AudienceFile): void => {
    if (audienceFile.bytesSize > environment.maxFileSizeBytesAllowed)
    {
      this.dialogService.showError("The file size is bigger than the max size allowed to download. Contact the portal team to retrieve the file.");
      return;
    }

    this.spinnerService.show();
    this.audiencesService.downloadAudienceFile(audience.id, audienceFile.id).subscribe(
      (result: Blob) => {
        /*
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
          window.navigator.msSaveOrOpenBlob(csvData, file_name);
        } else { // for Non-IE (chrome, firefox etc.)*/
        let fileName: string;
        let targetPlatforms: any = AudiencesFilesTargetPlatforms;
        switch(audienceFile.targetPlatform) {
          case AudiencesFilesTargetPlatforms.WAMProxy:
            let customSegmentId: string = audienceFile.customAttributes.find(x => x.attributeId == AudiencesFilesAttributes.CustomSegmentId)?.value;
            fileName = `8415_${audience.name.replace(/[\/|\\:*?"<>]/g, " ")}_CS_${customSegmentId.replace(/[\/|\\:*?"<>]/g, " ")}.zip`; 
            break;  
          case AudiencesFilesTargetPlatforms.Xandr:
            fileName = `${targetPlatforms[audienceFile.targetPlatform]}_${audience.name.replace(/[\/|\\:*?"<>]/g, " ")}.avro`; 
            break;            
          case AudiencesFilesTargetPlatforms.TapTap:
            fileName = `${targetPlatforms[audienceFile.targetPlatform]}_${audience.name.replace(/[\/|\\:*?"<>]/g, " ")}.csv.gz`; 
            break;              
          case AudiencesFilesTargetPlatforms.TapTapOOH:
            fileName = `${targetPlatforms[audienceFile.targetPlatform]}_${audience.name.replace(/[\/|\\:*?"<>]/g, " ")}.csv`; 
            break;                
          default:
            fileName = `${targetPlatforms[audienceFile.targetPlatform]}_${audience.name.replace(/[\/|\\:*?"<>]/g, " ")}.zip`; 
            break;      
        }
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = URL.createObjectURL(result);
        a.download = fileName;

        this.spinnerService.hide();

        a.click();
        URL.revokeObjectURL(a.href)
        a.remove();
      },
      () => {
        this.spinnerService.hide();
      });
  }

  deleteAudience = (audience: Audience): void => {
    let _this: AudiencesListComponent = this;
    this.dialogCustomButtonsService.showMessage(
      'Audience archive/removal',
      `Are you sure to archive/remove "${audience.name}"?\r\nWhen archiving, scheduling sets for the audience will be removed.`,
      [ 'Archive', 'Delete' ],
      (buttonText: string) => {
        let cloned: PublicisTableDataSource = { ..._this.audiencesDataSource};
        let index: number = cloned.data.findIndex(x => x.id == audience.id);
        cloned.data.splice(index, 1);
        _this.audiencesDataSource = cloned;     
        switch(buttonText) {
          case 'Archive':
            _this.audiencesService.archiveAudience(audience.id).subscribe(
              () => { _this.startRefreshPolling(); },
              () => { _this.startRefreshPolling(); }
            );
            break;
          default:
            _this.audiencesService.removeAudience(audience.id).subscribe(
              () => { _this.startRefreshPolling(); },
              () => { _this.startRefreshPolling(); }
            );
            break;
        }
      }, 
      () => { _this.startRefreshPolling(); },
      null,
      true,
      true
    );
  }

  addComment = (audience: Audience): void => {
    let _this: AudiencesListComponent = this;
    this.textAreaFieldDialogService.show(
      'Add comment',
      'Comment',
      `Add the following comment to the audience.`,
      (comment: string) => {
          _this.audiencesService.addComment(audience.id, {
            audienceId: audience.id,
            comment: comment            
          }).subscribe(
            () => { _this.startRefreshPolling(); },
            () => { _this.startRefreshPolling(); }
          );
      }, 
      () => { _this.startRefreshPolling(); }
    );
  }  

  deleteAudienceFile = (audience: Audience, audienceFile: AudienceFile): void => {
    let customSegmentDescription: string = audienceFile.customAttributes.find(x => x.attributeId == AudiencesFilesAttributes.CustomSegmentDescription)?.value;
    let confirmation: string = !!customSegmentDescription ? `Are you sure to remove file for custom segment "${customSegmentDescription}"?` : 'Are you sure to remove file?' 
    this.dialogService.showConfirmation(confirmation, 
      () => {
        this.audiencesService.removeAudienceFile(audience.id, audienceFile.id).subscribe(
          () => {
            let audienceFileIndex: number = audience?.audienceFiles?.findIndex(x => x.id == audienceFile.id);
            audience?.audienceFiles?.splice(audienceFileIndex, 1);
          });
      },
      () => { });
  }

  sendAudienceFile = (audience: Audience, audienceFile: AudienceFile): void => {
    let customSegmentDescription: string = audienceFile.customAttributes.find(x => x.attributeId == AudiencesFilesAttributes.CustomSegmentDescription)?.value;
    let confirmation: string = !!customSegmentDescription ? `Are you sure to send file for custom segment "${customSegmentDescription}"?` : 'Are you sure to send file?' 
    this.dialogService.showConfirmation(confirmation, 
      () => {
        this.audiencesService.sendAudienceFile(audience.id, audienceFile.id).subscribe(
          () => {
            this.dialogService.showMessage("Audience file sent", "Audience file sent");
          });
      },
      () => { });    
  }

  getAudienceFileIcon = (audienceFile: AudienceFile): string => {    
    return `assets/images/target-platforms/${audienceFile.targetPlatform}.png`;
  }

  toggleAudienceFilesVisibility  = (audience: Audience): void => {
    this.filesVisibleAudienceId = (this.filesVisibleAudienceId != audience.id) ? audience.id : undefined;
  }

  filterAudiences = (): void => {
    // We need to build the full object in order to make the publicis-table component detect changes
    let cloned: PublicisTableDataSource = { ...this.audiencesDataSource};
    cloned.data =         
      !!this.audiencesFilter.textSearch ? 
      this.baseAudiences
        .filter(this.isAudienceInTagsFilter)
        .filter((x: Audience) => { 
          return (
            x.id.toString().indexOf(this.audiencesFilter.textSearch.toUpperCase()) != -1 ||
            x.category.name.toUpperCase().indexOf(this.audiencesFilter.textSearch.toUpperCase()) != -1 ||
            x.name.toUpperCase().indexOf(this.audiencesFilter.textSearch.toUpperCase()) != -1 ||
            x.description.toUpperCase().indexOf(this.audiencesFilter.textSearch.toUpperCase()) != -1 ||
            this.getStatusLabel(x).toUpperCase().indexOf(this.audiencesFilter.textSearch.toUpperCase()) != -1
          ); 
        } ) :
      this.baseAudiences.filter(this.isAudienceInTagsFilter);
    this.audiencesDataSource = cloned;
  }

  showAudienceDetails =  (audience: Audience) : void => {
    this.audienceInfoDialogService.show(audience);
  }

  calculateLookAlikeParameters =  (audience: Audience) : void => {
    this.dialogService.showConfirmation(
      `Are you sure to calculate "${audience.name}" look alike parameters?`,
      () => {
        this.lookAlikesService.calculateLookAlikesParameters(LookAlikeSourceTypes.Audience, audience.id.toString()).subscribe(() => { });
      },
      () => { });
  }

  showLookAlikes =  (audience: Audience) : void => {
    this.router.navigate([ '/audiences', audience.id, 'look-alikes' ]);
  }

  moveAudience =  (audience: Audience) : void => {
    let _this: AudiencesListComponent = this;
    this.companySelectorService.showCompanySelector("Select the target company", (selectedCompanyId: number) => {  
      audience.status = AudiencesStatus.MovingAudience;         
      _this.audiencesService.moveAudience(audience.id, +selectedCompanyId).subscribe(
          () => { 
            _this.dialogService.showMessage("Audience move", "Audience move process raised");
            _this.startRefreshPolling();
          },
          () => { _this.startRefreshPolling(); }
      );
    }, true);  
  }

  manageAudienceTags =  (audience: Audience) : void => {
    let _this: AudiencesListComponent = this;
    this.audienceTagsDialogService.show(audience, () => {   
      _this.spinnerService.show();     
      _this.audiencesService.updateTags(audience.id, audience.tags).subscribe(
          () => { 
            _this.spinnerService.hide();
            _this.startRefreshPolling();
          },
          () => { 
            _this.spinnerService.hide();
            _this.startRefreshPolling(); 
          }
      );
    }, () =>{ _this.startRefreshPolling(); } );  
  }

  hasIdentifiers = (audience: Audience): boolean => {
    return Object.values(audience.idGraphCounts).filter(x => x > 0).length > 0;
  }

  private isAudienceInTagsFilter = (audience: Audience): boolean => {
    if(this.audiencesFilter.tagsSearch.length == 0)
      return true;

    if(!audience.tags)
      return false;

    let result: boolean = false;
    for(const tag of audience.tags) {
      if(this.audiencesFilter.tagsSearch.findIndex(x => x.id == tag.id) >= 0) {
        result = true;
        break;
      }
    };

    return result;
  }

  showFilterDialog =  () : void => {
    this.audiencesListFilterDialogService.show(this.tags, this.audiencesFilter, () => {   
      if (!!this.audiencesDataSource.serverSideConfiguration) {
        this.loadAudiences(true);
      }
      else {
        this.filterAudiences();
      }
    }, () =>{} );  
  }

  isFilterApplied =  () : boolean => {
    return !!this.audiencesFilter.textSearch || this.audiencesFilter.tagsSearch.length > 0;  
  }

  downloadRational = (audience: Audience): void => {
    this.spinnerService.show();
    this.audiencesService.downloadRational(audience.id).subscribe(
      (result: Blob) => {
        /*
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
          window.navigator.msSaveOrOpenBlob(csvData, file_name);
        } else { // for Non-IE (chrome, firefox etc.)*/
        let fileName: string = `${audience.name.replace(/[\/|\\:*?"<>]/g, " ")}.docx`;

        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = URL.createObjectURL(result);
        a.download = fileName;

        this.spinnerService.hide();

        a.click();
        URL.revokeObjectURL(a.href)
        a.remove();
      },
      () => {
        this.spinnerService.hide();
      });
  }

  stopRefreshPolling = (): void => {    
    if (!!this.refreshTimerSubscription)
      this.refreshTimerSubscription.unsubscribe();
    
    this.activePolling = false;

    console.debug("stopRefreshPolling executed")
  }

  startRefreshPolling = () : void => {
    this.stopRefreshPolling();
    this.activePolling = true;
    this.refreshTimerSubscription = timer(2000).subscribe(() => this.loadAudiences());

    console.debug("startRefreshPolling executed")
  }  

  onMenuClosed = (closeReason: MenuCloseReason) : void => {
    if (!closeReason)
      this.startRefreshPolling();
  }

  getFileAttributeDescription = (attribute: AudiencesFilesAttributes, targetPlatform: AudiencesFilesTargetPlatforms) : string => {
    switch(attribute) {
      case AudiencesFilesAttributes.CustomSegmentId:
        return "Custom segment id";
      case AudiencesFilesAttributes.ZipPassword:
        return "Zip password";
      case AudiencesFilesAttributes.CustomSegmentDescription:
        return "Custom segment name";
      case AudiencesFilesAttributes.AudienceExternalIdentifier:
        switch(targetPlatform) 
        {
          case AudiencesFilesTargetPlatforms.Amazon:
            return "Amazon audience id";
          case AudiencesFilesTargetPlatforms.Meta:
            return "Meta audience id";
          case AudiencesFilesTargetPlatforms.WAMProxy:
            return "WAM audience id";    
          default:
            return "External audience identifier";                      
        }; 
      case AudiencesFilesAttributes.AudienceExternalName:
        switch(targetPlatform) 
        {
          case AudiencesFilesTargetPlatforms.Amazon:
            return "Amazon audience name";
          case AudiencesFilesTargetPlatforms.Meta:
            return "Meta audience name";
          case AudiencesFilesTargetPlatforms.WAMProxy:
            return "WAM audience name";    
          default:
            return "External audience name";                      
        };
      case AudiencesFilesAttributes.ProfileExternalIdentifier:
        switch(targetPlatform) 
        {
          case AudiencesFilesTargetPlatforms.Amazon:
            return "Amazon profile id";
          case AudiencesFilesTargetPlatforms.Meta:
            return "Meta business profile id";
          default:
            return "External profile identifier";                      
        };                 
      case AudiencesFilesAttributes.AdvertiserExternalIdentifier:
        switch(targetPlatform) 
        {
          case AudiencesFilesTargetPlatforms.Amazon:
            return "Amazon advertiser id";
          case AudiencesFilesTargetPlatforms.Meta:
            return "Meta ad account id";
          default:
            return "External advertiser identifier";                      
        };               
      case AudiencesFilesAttributes.AudienceExternalCategoryName:
        return "External audience category name";
      default:
        return "Missing attribute description";

    };
  }
}
