import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../core/http.service';
import { Observable } from 'rxjs';
import { GlobalsService } from '../globals.service';
import { Business } from 'src/app/models/services/audiences/meta/business';
import { AdAccount } from 'src/app/models/services/audiences/meta/adAccount';
import { CustomAudience } from 'src/app/models/services/audiences/meta/customAudience';


@Injectable({
  providedIn: 'root'
})
export class MetaAudiencesService {

  constructor(
    private httpService: HttpService,
    private globalsService: GlobalsService
  ) { }

  public getBusinesses(): Observable<Business[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/meta/businesses`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }

  public getAdAccounts(businessId: string): Observable<AdAccount[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/meta/businesses/${businessId}/ad-accounts`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }  

  public getCustomAudiences(businessId: string, adAccountId: string): Observable<CustomAudience[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/meta/businesses/${businessId}/ad-accounts/${adAccountId}/custom-audiences`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }  

  public getCustomAudienceDetail(businessId: string, adAccountId: string, audienceId: string): Observable<CustomAudience> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/meta/businesses/${businessId}/ad-accounts/${adAccountId}/custom-audiences/${audienceId}`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  } 
}