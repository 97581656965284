import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { A3mediaAudiencesService } from 'src/app/services/audiences/a3media-audiences.service';

@Component({
  selector: 'app-a3media-attributes',
  templateUrl: './a3media-attributes.component.html',
  styleUrls: ['./a3media-attributes.component.sass']
})
export class A3mediaAttributesComponent implements OnInit {
    @ViewChild('matAutocompleteAudiences') inputAutocompleteAudiences: ElementRef<HTMLInputElement>;
  
    @Input() 
    form: FormGroup;
  
    @Input() 
    updateValueAndValidity: () => void;

    @Input() 
    erisAudienceName: string;
  
    audiences: string[] = [];
    filteredAudiences: string[] = [];
    newAudience: string = '0';
  
    constructor(
      private a3MediaAudiencesServices: A3mediaAudiencesService,
      private spinnerService: NgxSpinnerService
    ) {
    }
  
    ngOnInit(): void {
      this.spinnerService.show("dialogSpinner");
      this.a3MediaAudiencesServices.getAudiences().subscribe(
        resp => {
          this.audiences = resp;
          this.onNewAudienceSelectedChange();
          
          this.spinnerService.hide("dialogSpinner");
          this.onAudienceChange(null);
        }
      )
    }
     
    resetAudiencesFilter = (): void => {
      this.inputAutocompleteAudiences.nativeElement.value = '';
      this.form.controls.audienceName.setValue(null);
      this.filteredAudiences = this.audiences;
      this.updateValueAndValidity();
    }
      
    filterAudiences = (): void => {
      const filterValue = this.inputAutocompleteAudiences.nativeElement.value.toLowerCase();
      this.filteredAudiences = this.audiences.filter(o => o.toLowerCase().includes(filterValue));
      if (filterValue != this.form.controls.audienceName.value?.toLowerCase())
        this.form.controls.audienceName.setValue(null);
      
      this.updateValueAndValidity();
    }
  
    onAudienceChange = ($event: MatAutocompleteSelectedEvent): void => {    
      if (this.audiences.length > 0) {
        this.inputAutocompleteAudiences.nativeElement.blur();
        this.form.controls.audienceName.setValue(this.inputAutocompleteAudiences.nativeElement.value);
        this.updateValueAndValidity();  
      }
    }
  
    onAudienceDescriptionChange = (): void => {
      this.updateValueAndValidity();
    }

    onNewAudienceSelectedChange = (): void => {
      this.form.controls.audienceName.setValue(!!(+this.newAudience) ? this.erisAudienceName : null);
      this.form.controls.audienceName.updateValueAndValidity();
      this.updateValueAndValidity();      
    }

  }
  