import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdAccount } from 'src/app/models/services/audiences/meta/adAccount';
import { Business } from 'src/app/models/services/audiences/meta/business';
import { CustomAudience } from 'src/app/models/services/audiences/meta/customAudience';
import { SharedAccountInfo } from 'src/app/models/services/audiences/meta/sharedAccountInfo';
import { MetaAudiencesService } from 'src/app/services/audiences/meta-audiences.service';

@Component({
  selector: 'app-meta-attributes',
  templateUrl: './meta-attributes.component.html',
  styleUrls: ['./meta-attributes.component.sass']
})
export class MetaAttributesComponent implements OnInit {
  @ViewChild('matAutocompleteBusinesses') inputAutocompleteBusinesses: ElementRef<HTMLInputElement>;
  @ViewChild('matAutocompleteAdAccounts') inputAutocompleteAdAccounts: ElementRef<HTMLInputElement>;
  @ViewChild('matAutocompleteAudiences') inputAutocompleteAudiences: ElementRef<HTMLInputElement>;
  @ViewChild('matAutocompleteBusinessesShare') inputAutocompleteBusinessesShare: ElementRef<HTMLInputElement>;
  @ViewChild('matAutocompleteAdAccountsShare') inputAutocompleteAdAccountsShare: ElementRef<HTMLInputElement>;

  @Input() 
  form: FormGroup;

  @Input() 
  updateValueAndValidity: () => void;

  @Input() 
  erisAudienceName: string;

  businesses: Business[] = [];
  filteredBusinesses: Business[] = [];
  adAccounts: AdAccount[] = [];
  filteredAdAccounts: AdAccount[] = [];
  audiences: CustomAudience[] = [];
  filteredAudiences: CustomAudience[] = [];
  businessesShare: Business[] = [];
  filteredBusinessesShare: Business[] = [];
  adAccountsShare: AdAccount[] = [];
  filteredAdAccountsShare: AdAccount[] = [];
  newAudience: string = '0';
  formShare: FormGroup;
  
  constructor(
    private metaAudiencesServices: MetaAudiencesService,
    private formBuilder: FormBuilder,
    private spinnerService: NgxSpinnerService
  ) {
   
  }

  ngOnInit(): void {
    this.spinnerService.show("dialogSpinner");
    this.metaAudiencesServices.getBusinesses().subscribe(
      resp => {
        this.businesses = resp;
        this.businessesShare = resp;

        this.onNewAudienceSelected();

        this.spinnerService.hide("dialogSpinner");        
      }
    );

    this.formShare = this.formBuilder.group({
      businessShareId: ['', Validators.required],
      adAccountShareId:  ['', Validators.required],
      adAccountShareKnownId:  ['', Validators.required]
    });      
  }

  onBusinessChange = ($event: MatAutocompleteSelectedEvent): void => {
    this.spinnerService.show("dialogSpinner");
    this.resetAdAccountControls([]);
    let _this: MetaAttributesComponent = this;
    this.metaAudiencesServices.getAdAccounts($event.option.value).subscribe(
      resp => {
        this.adAccounts = resp;
        this.spinnerService.hide("dialogSpinner");
        this.inputAutocompleteBusinesses.nativeElement.blur();        
      }
    )
  }

  getBusinessDescription = (id?: string): string => {
    return this.businesses.find(x => x.id == id)?.name;
  }

  resetBusinessesFilter = (): void => {
    this.inputAutocompleteBusinesses.nativeElement.value = '';
    this.resetBusinessControls(this.businesses);
  }

  filterBusinesses = (): void => {
    const filterValue = this.inputAutocompleteBusinesses.nativeElement.value.toLowerCase();
    this.resetBusinessControls(this.businesses.filter(o => o.name.toLowerCase().includes(filterValue)));
  }

  onAdAccountChange = ($event: MatAutocompleteSelectedEvent): void => {      
    this.resetAudienceControls([]);  
    if (!(+this.newAudience)) {
      this.spinnerService.show("dialogSpinner");
      this.metaAudiencesServices.getCustomAudiences(this.form.controls.businessId.value, $event.option.value).subscribe(
        resp => {
          this.audiences = resp;          
          this.spinnerService.hide("dialogSpinner");
          this.inputAutocompleteAdAccounts.nativeElement.blur();          
          this.updateValueAndValidityConsolidated();
        }
      )
    }
  }

  getAdAccountDescription = (id?: number): string => {
    return this.adAccounts.find(x => x.accountId == id)?.name;
  }
  
  resetAdAccountsFilter = (): void => {
    this.inputAutocompleteAdAccounts.nativeElement.value = '';
    this.resetAdAccountControls(this.adAccounts);
  }

  filterAdAccounts = (): void => {
    const filterValue = this.inputAutocompleteAdAccounts.nativeElement.value.toLowerCase();
    this.resetAdAccountControls(this.filteredAdAccounts = this.adAccounts.filter(o => o.name.toLowerCase().includes(filterValue)));
  }

  getAudienceDescription = (id?: string): string => {
    let audience: CustomAudience = this.audiences.find(x => x.id == id);
    return !!audience ? `${audience.id} - ${audience.name}` : '';
  }
  
  resetAudiencesFilter = (): void => {
    this.inputAutocompleteAudiences.nativeElement.value = '';
    this.resetAudienceControls(this.audiences);
  }
    
  filterAudiences = (): void => {
    const filterValue = this.inputAutocompleteAudiences.nativeElement.value.toLowerCase();
    this.resetAudienceControls(this.audiences.filter(o => `${o.id} - ${o.name}`.toLowerCase().includes(filterValue)));
    if (filterValue != this.form.controls.audienceName.value?.toLowerCase())
    {
      this.form.controls.audienceName.setValue(null);
      this.updateValueAndValidityConsolidated();  
    }  
  }

  onAudienceChange = ($event: MatAutocompleteSelectedEvent): void => {    
    let audienceName: string = this.audiences.find(x => x.id == $event.option.value).name;
    this.form.controls.audienceName.setValue(audienceName);
    this.spinnerService.show("dialogSpinner");
    this.metaAudiencesServices.getCustomAudienceDetail(this.form.controls.businessId.value, this.form.controls.adAccountId.value, $event.option.value).subscribe(
      resp => {
        if (!!resp.sharedAccountInfo)
          this.form.controls.sharedAccounts.setValue(resp.sharedAccountInfo);          
        this.spinnerService.hide("dialogSpinner");
        this.inputAutocompleteAudiences.nativeElement.blur();          
        this.updateValueAndValidityConsolidated();
      },
      () => {
        this.resetAdAccountControls(this.adAccounts);
        this.spinnerService.hide("dialogSpinner");
      }
    )
  }

  onNewAudienceSelected = (): void => {
    this.form.controls.audienceId.setValue(null);
    this.form.controls.audienceName.setValue(this.erisAudienceName);
    if (!!(+this.newAudience))
      this.form.controls.audienceId.setValidators([]);
    else
      this.form.controls.audienceId.setValidators([Validators.required]);

    this.form.controls.sharedAccounts.setValue([]);
    
    this.form.controls.audienceId.updateValueAndValidity();
    this.form.controls.audienceName.updateValueAndValidity();

    this.updateValueAndValidityConsolidated();
  }

  resetBusinessControls = (filteredBusinesses: Business[]): void => {
    this.filteredBusinesses = filteredBusinesses;
    if (filteredBusinesses == this.businesses)
      this.form.controls.adAccountId.setValue(null);
    if (!!this.inputAutocompleteAdAccounts)
      this.inputAutocompleteAdAccounts.nativeElement.value = '';

    this.adAccounts = [];
    this.resetAdAccountControls([]);
  }

  resetAdAccountControls = (filteredAdAccounts: AdAccount[]): void => {
    this.filteredAdAccounts = filteredAdAccounts;
    if (filteredAdAccounts == this.adAccounts || filteredAdAccounts.length == 0)
      this.form.controls.adAccountId.setValue(null);          
    if (!!this.inputAutocompleteAudiences)
      this.inputAutocompleteAudiences.nativeElement.value = '';

    this.audiences = [];
    this.resetAudienceControls([]);
  }
  
  resetAudienceControls = (filteredAudiences: CustomAudience[]): void => {
    this.filteredAudiences = filteredAudiences;
    if (filteredAudiences == this.audiences)
      this.form.controls.audienceId.setValue(null);
    if (!(+this.newAudience))
      this.form.controls.audienceName.setValue(null);

    this.form.controls.sharedAccounts.setValue([]);
    this.form.controls.audienceId.updateValueAndValidity();
    this.form.controls.audienceName.updateValueAndValidity();
    this.updateValueAndValidityConsolidated();
  }

  // SHARING controls
  
  onBusinessShareChange = ($event: MatAutocompleteSelectedEvent): void => {
    this.spinnerService.show("dialogSpinner");
    this.resetAdAccountShareControls([]);
    let _this: MetaAttributesComponent = this;
    this.metaAudiencesServices.getAdAccounts($event.option.value).subscribe(
      resp => {
        this.adAccountsShare = resp;
        this.spinnerService.hide("dialogSpinner");
        this.inputAutocompleteBusinessesShare.nativeElement.blur();        
      }
    )
  }

  getBusinessShareDescription = (id?: string): string => {
    return this.businessesShare.find(x => x.id == id)?.name;
  }

  resetBusinessesShareFilter = (): void => {
    this.inputAutocompleteBusinessesShare.nativeElement.value = '';
    this.resetBusinessShareControls(this.businessesShare);
  }

  filterBusinessesShare = (): void => {
    const filterValue = this.inputAutocompleteBusinessesShare.nativeElement.value.toLowerCase();
    this.resetBusinessShareControls(this.businessesShare.filter(o => o.name.toLowerCase().includes(filterValue)));
  }

  getAdAccountShareDescription = (id?: number): string => {
    return this.adAccountsShare.find(x => x.accountId == id)?.name;
  }
  
  resetAdAccountsShareFilter = (): void => {
    this.inputAutocompleteAdAccountsShare.nativeElement.value = '';
    this.resetAdAccountShareControls(this.adAccountsShare);
  }

  filterAdAccountsShare = (): void => {
    const filterValue = this.inputAutocompleteAdAccountsShare.nativeElement.value.toLowerCase();
    this.resetAdAccountShareControls(this.filteredAdAccountsShare = this.adAccountsShare.filter(o => o.name.toLowerCase().includes(filterValue)));
  }

  resetBusinessShareControls = (filteredBusinessesShare: Business[]): void => {
    this.filteredBusinessesShare = filteredBusinessesShare;
    if (filteredBusinessesShare == this.businessesShare)
      this.formShare.controls.adAccountShareId.setValue(null);
    if (!!this.inputAutocompleteAdAccountsShare)
      this.inputAutocompleteAdAccountsShare.nativeElement.value = '';

    this.adAccountsShare = [];
    this.resetAdAccountShareControls([]);
  }

  resetAdAccountShareControls = (filteredAdAccountsShare: AdAccount[]): void => {
    this.filteredAdAccountsShare = filteredAdAccountsShare;
    if (filteredAdAccountsShare == this.adAccountsShare || filteredAdAccountsShare.length == 0)
      this.formShare.controls.adAccountShareId.setValue(null);
    
    this.updateValueAndValidityConsolidated();
  }
  
  isSharingSectionVisible = (): boolean => {
    return !!+this.newAudience || !!this.form.controls.audienceId.value;
  }

  updateValueAndValidityConsolidated = (): void => {
    this.updateValueAndValidity();
    this.formShare.updateValueAndValidity();
  }

  addSharing = (): void => {
    this.form.controls.sharedAccounts.value.push({
      businessId: this.formShare.controls.businessShareId.value,
      businessName: this.inputAutocompleteBusinessesShare.nativeElement.value,
      accountId: this.formShare.controls.adAccountShareId.value,
      accountName: this.inputAutocompleteAdAccountsShare.nativeElement.value,
      isNew: true
    });
    this.resetBusinessesShareFilter()
  }

  addSharingKnownId = (): void => {
    this.form.controls.sharedAccounts.value.push({
      //businessId: this.formShare.controls.businessShareId.value,
      //businessName: this.inputAutocompleteBusinessesShare.nativeElement.value,
      accountId: this.formShare.controls.adAccountShareKnownId.value,
      isNew: true
    });
    this.resetBusinessesShareFilter()
  }

  isAddSharingVisible = (): boolean => {
    return this.formShare.controls.businessShareId.valid && this.formShare.controls.adAccountShareId.valid && 
      this.formShare.controls.adAccountShareId.value != this.form.controls.adAccountId.value &&
      this.form.controls.sharedAccounts.value.findIndex(x => x.accountId == this.formShare.controls.adAccountShareId.value) == -1;
  }

  isAddSharingKnownIdVisible = (): boolean => {
    return this.formShare.controls.adAccountShareKnownId.valid && 
      this.form.controls.sharedAccounts.value.findIndex(x => x.accountId == this.formShare.controls.adAccountShareKnownId.value) == -1;
  }

  getSharedAccountDescription = (sharedAccount: SharedAccountInfo): string => {
    return !!sharedAccount.businessId ? `${sharedAccount.businessName} \\ ${sharedAccount.accountName}` : sharedAccount.accountId;
  }

  removeSharedAccount = (sharedAccount: SharedAccountInfo): void => {
    let sharedAccountIndex: number = this.form.controls.sharedAccounts.value.findIndex(x => x.accountId == sharedAccount.accountId);
    this.form.controls.sharedAccounts.value.splice(sharedAccountIndex, 1);
  }
}
