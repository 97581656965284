import { Component, OnInit } from '@angular/core';
import { IdentifierCount, TaxonomyItem, TaxonomyItemValueTypes } from 'src/app/models/services/taxonomiesConfig';
import { Rule, RuleSet } from 'angular2-query-builder';
import { AttributeValueAffinitySelectorDialogService } from 'src/app/services/dialogs/attribute-value-affinity-selector-dialog.service';
import { AttributeValueDateSelectorDialogService } from 'src/app/services/dialogs/attribute-value-date-selector-dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { timer } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { AttributeValueAudienceOverlapSelectorDialogService } from 'src/app/services/dialogs/attribute-value-audience-overlap-selector-dialog.service';

export interface SelectedTaxonomyItem {
  description: string,
  item: TaxonomyItem,
  rule?: Rule
}

export enum SortType {
  ByNameAsc = 1,
  ByProfilesCountDesc = 2
}

@Component({
  selector: 'app-audience-rule-builder-dialog',
  templateUrl: './audience-rule-builder-dialog.component.html',
  styleUrls: ['./audience-rule-builder-dialog.component.sass']
})
export class AudienceRuleBuilderDialogComponent implements OnInit {

  public accept: (rule: Rule | RuleSet) => void;
  public dismiss: () => void;
  public taxonomy: TaxonomyItem[];
  public minimumProfilesNumber: number = 1000;
  public showProfilesCount: boolean = true;
  public countType: string = '1';
  public countIdentifierType: string = '1';
  public sortType: SortType = SortType.ByNameAsc;
  private globalSearchTerm: string = '';

  isGlobalFilterApplied: boolean = false;
  filteredTaxonomy: TaxonomyItem[];  
  navigationPath: TaxonomyItem[] = [];
  currentTaxonomyItems: TaxonomyItem[];
  currentFilteredTaxonomyItems: TaxonomyItem[];
  selectedTaxonomyItems: SelectedTaxonomyItem[] = [];
  title: string; 
  

  constructor(
    private attributeValueAffinitySelectorDialogService: AttributeValueAffinitySelectorDialogService,
    private attributeValueDateSelectorDialogService: AttributeValueDateSelectorDialogService,
    private attributeValueAudienceOverlapSelectorDialogService: AttributeValueAudienceOverlapSelectorDialogService,
    private spinnerService: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.title = "Add a rule";
    this.refreshGlobal();
  }

  groupByToMap = <T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q) =>
    array.reduce((map, value, index, array) => {
      const key = predicate(value, index, array);
      map.get(key)?.push(value) ?? map.set(key, [value]);
      return map;
    }, new Map<Q, T[]>());    
  
  onAccept = (): void => {
    let rules: Rule[] = [];
    
    this.selectedTaxonomyItems.forEach(x => {
      rules.push(
        !!x.rule ?
        x.rule : {
        field: x.item.field,
        operator: '=',
        value: x.item.value
      });
    });

    if (rules.length == 1) 
      this.accept(rules[0]);
    else {
      let ruleset: RuleSet = {
        condition: 'or',
        rules: rules
      }
      this.accept(ruleset);
    }
  };

  onDismiss = (): void => {
    this.dismiss();
  }

  onTaxonomyItemClick = (taxonomyItem: TaxonomyItem): void => {
    var currentIndex = this.selectedTaxonomyItems.findIndex(x => x.item == taxonomyItem); 
    if(currentIndex >= 0) {
      this.selectedTaxonomyItems.splice(currentIndex, 1);
    } else {
      switch(taxonomyItem.valueType) {
        case TaxonomyItemValueTypes.Affinity:
          this.attributeValueAffinitySelectorDialogService.show(taxonomyItem, rule => {
            this.selectedTaxonomyItems.push({
              description: `${this.getNavigationPathString()} / ${taxonomyItem.name} affinity ${rule.operator} ${rule.value}`,
              item: taxonomyItem,
              rule: rule
            });
          })
          break;

        case TaxonomyItemValueTypes.Date:
          this.attributeValueDateSelectorDialogService.show(taxonomyItem, rule => {
            var formattedDate = new Date(rule.value).toLocaleString('en-EN', { year: 'numeric', month: 'long', day: 'numeric' });
            this.selectedTaxonomyItems.push({
              description: `${this.getNavigationPathString()} / ${taxonomyItem.name} ${rule.operator} ${formattedDate}`,
              item: taxonomyItem,
              rule: rule
            });
          })
          break;

          case TaxonomyItemValueTypes.Audience:
            this.attributeValueAudienceOverlapSelectorDialogService.show(taxonomyItem, rule => {
              this.selectedTaxonomyItems.push({
                description: `Identifiers ${rule.operator} ${taxonomyItem.name}`,
                item: taxonomyItem,
                rule: rule
              });
            })
            break;
            
        default: 
          this.selectedTaxonomyItems.push({
            description: `${this.getNavigationPathString()} / ${taxonomyItem.name}`,
            item: taxonomyItem
          });  
          break;
      }
    }
  };

  onGoPrevious = (): void => {
    this.navigationPath.splice(this.navigationPath.length - 1, 1);
    this.currentTaxonomyItems = this.navigationPath.length > 0 ? this.navigationPath[this.navigationPath.length - 1].childs : this.filteredTaxonomy;
    this.currentFilteredTaxonomyItems = this.currentTaxonomyItems;
  }

  showPreviousButton = (): boolean => {
    return this.navigationPath.length > 0;
  }

  showNextButton = (): boolean => {
    return this.selectedTaxonomyItems.length == 1 && !!this.selectedTaxonomyItems[0].item.childs;
  }  

  showAcceptButton = (): boolean => {
    return this.selectedTaxonomyItems.filter(x => !x.item.isOnlyGroup).length > 0;
  }  

  getNavigationPathString = (): string => {
    return this.navigationPath.map(x => x.name).join(' / ');
  }

  navigateInside = (taxonomyItem: TaxonomyItem): void => {
    this.navigationPath.push(taxonomyItem);
    this.currentTaxonomyItems = this.navigationPath[this.navigationPath.length - 1].childs;
    this.currentFilteredTaxonomyItems = this.currentTaxonomyItems;
  }

  isSelected = (taxonomyItem: TaxonomyItem): boolean => {
    return this.selectedTaxonomyItems.findIndex(x => x.item == taxonomyItem) >= 0;
  }

  searchGlobal = (searchBoxName: string, searchTerm: string) => {
    this.globalSearchTerm = searchTerm;
    this.refreshGlobal();
  }

  refreshGlobal = () => {    
    this.isGlobalFilterApplied = this.globalSearchTerm != '';
    this.spinnerService.show();
    let searchRegExp: RegExp = new RegExp(this.globalSearchTerm, 'i');
    timer(500).subscribe(() => {
      let taxonomyCopy: TaxonomyItem[] = JSON.parse(JSON.stringify(this.taxonomy));
      this.filterFullTaxonomy(taxonomyCopy, searchRegExp);
      this.filteredTaxonomy = taxonomyCopy;
      this.currentTaxonomyItems = this.filteredTaxonomy;
      this.currentFilteredTaxonomyItems = this.filteredTaxonomy;
      this.navigationPath = [];
      this.spinnerService.hide();
    });  
  }

  filterFullTaxonomy = (taxonomyItems: TaxonomyItem[], searchRegExp: RegExp): void => {    
    let i: number = 0;
    var sortLevel = true;
    while(i < taxonomyItems.length)
    {
      let item: TaxonomyItem = taxonomyItems[i];
      sortLevel = sortLevel && !!item.profilesCount;
      if (!!item.childs)
        this.filterFullTaxonomy(item.childs, searchRegExp);      
      
      if (
          (
            (this.isGlobalFilterApplied && !item.name.match(searchRegExp)) || 
            !(item.profilesCount >= this.minimumProfilesNumber)
          ) && 
          (!item.childs || item.childs.length == 0)
      )
        taxonomyItems.splice(i, 1);
      else
        i++;
    }

    if (sortLevel)
      taxonomyItems.sort((x, y) => {
        switch(+this.sortType) {
          case SortType.ByProfilesCountDesc:
            return x.profilesCount > y.profilesCount ? -1 : (x.profilesCount < y.profilesCount ? 1 : 0);
          default:
            return x.name > y.name ? 1 : (x.name < y.name ? -1 : 0);
        }
      })
  }

  isCurrentSelectionSearchVisible = (): boolean => {
    return !this.isGlobalFilterApplied && this.currentTaxonomyItems?.length > 50;
  }

  searchCurrentSelection = (searchBoxName: string, searchTerm: string): void => {    
    this.spinnerService.show();
    let searchRegExp: RegExp = new RegExp(searchTerm, 'i');
    timer(500).subscribe(() => {
      this.currentFilteredTaxonomyItems = this.currentTaxonomyItems.filter( x => !!x.name.match(searchRegExp));
      this.spinnerService.hide();
    });
  }

  getSelectedCount = (taxonomyItem: TaxonomyItem): number => {
    switch(this.countType) {
      case '1':
        return taxonomyItem.profilesCount;
      case '2':
        let identifierCount: IdentifierCount = taxonomyItem.identifiersCounts.find(x => x.idGraphTypeId == +this.countIdentifierType);
        return identifierCount?.identifierCount;
      default:
        return 0;
    }
  }

  onShowCountsChange =  (event:MatCheckboxChange): void => {
    if (!event.checked) {
      this.countType = '1';
      this.countIdentifierType = '1';  
    }
  }

  onShowProfilesCountsChange =  (event:MatRadioChange): void => {
    if (event.value == '1') {
      this.countIdentifierType = '1';  
    }
  }  

  onSortTypeChange = (): void => {
    this.refreshGlobal();
  }
}


