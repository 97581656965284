import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErisProcess } from 'src/app/models/services/admin/erisProcess';
import { ErisProcessesInfo } from 'src/app/models/services/admin/erisProcessesInfo';
import { TimezoneDatePipe } from 'src/app/pipes/timezone-date.pipe';
import { DialogService } from 'src/app/services/core/dialog.service';
import { MonitorService } from 'src/app/services/monitor.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-maintenance-processes',
  templateUrl: './maintenance-processes.component.html',
  styleUrls: ['./maintenance-processes.component.sass']
})
export class MaintenanceProcessesComponent implements OnInit {

  heading: string = 'Processes';
  subheading: string = '';
  icon: string = 'pe-7s-config icon-gradient bg-midnight-bloom';
  loadFinished: boolean = false;

  erisProcessesInfo: ErisProcessesInfo;

  constructor(
    public usersService: UsersService,
    private monitorService: MonitorService,
    private spinnerService: NgxSpinnerService,
    private dialogService: DialogService,
    private timezoneDatePipe: TimezoneDatePipe
  ) { 
  }

  ngOnInit(): void {
    this.loadProcesses();
  }

  loadProcesses = (): void => {
    this.spinnerService.show();

    this.monitorService.getAllProcesses().subscribe(resp => {
      this.erisProcessesInfo = resp;
      this.loadFinished = true;
      this.spinnerService.hide();
    })
  }

  refresh = (): void => {
    this.erisProcessesInfo = {
      finishedProcesses: [],
      runningProcesses: []
    };
    this.loadProcesses();
  }

  showProcessDetail = (process: ErisProcess): void => { 
    this.spinnerService.show();
    this.monitorService.getProcessDetail(process.operationId).subscribe(resp => {
      this.spinnerService.hide();
      if (process.name != 'AudiencesAutomatedRefresh-ProcessEnqueuedRefresh' || process.endTime.toString() == '0001-01-01T00:00:00' || resp.length == 0 || resp.findIndex(x => x.message.includes('0 remaining audiences to refresh')) != -1) {
        this.dialogService.showMessage(
          "Process detail", 
          resp.map(x => `${this.timezoneDatePipe.transform(x.timestamp, 'yyyy:HH:mm')} - ${x.message}`).join('\n'), 
          { size: 'xl' }, 
          true);
      } else {
        this.dialogService.showConfirmationExt(
          "Process detail",
          resp.map(x => `${this.timezoneDatePipe.transform(x.timestamp, 'yyyy:HH:mm')} - ${x.message}`).join('\n').concat('\n\nDo you want to restart the refresh process?'), 
          { size: 'xl' }, 
          () => { 
            this.spinnerService.show();
            this.monitorService.relaunchAutomatedRefreshProcess(process.operationId).subscribe(() => {
              this.spinnerService.hide();
              this.dialogService.showMessage("Automated refresh", `Automated refresh process relaunched.`, {});
            }
          )},
          () => {},
          true);
      }
    })

  }

  markProcessAsIdle = (process: ErisProcess): void => { 
    this.spinnerService.show();
    this.monitorService.markProcessAsIdle(process.operationId).subscribe(resp => {
      this.spinnerService.hide();
      this.dialogService.showMessage("Mark as idle", `Process ${process.operationId} marked as idle.\nIt can take some minutes in being effective.`, {}, true);
    })

  }
}
