<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-12">
            <label>Select the target platform:</label>
            <div [formGroup]="form" class="form-group" style="text-align: center;">            
                <!--
                <ng-container *ngFor="let targetPlatformKey of targetPlatformsKeys">
                    <input type="radio" [value]="targetPlatformKey" name="targetPlatform" formControlName="targetPlatform" (change)="onChangeTargetPlaform($event)">
                    <label [for]="targetPlatformKey">
                        &nbsp;&nbsp;<img width="32px" height="32px" [src]="getAudienceFileIcon(targetPlatformKey)" />&nbsp;&nbsp;&nbsp;&nbsp;{{targetPlatforms[targetPlatformKey]}}
                    </label>          
                    <br/>      
                </ng-container>
                -->
                <mat-button-toggle-group style="width: 1105px;flex-wrap: wrap;" formControlName="targetPlatform" name="targetPlatformSelector" aria-label="Target platform" (change)="onChangeTargetPlaform()">
                    <mat-button-toggle style="width: 20%;" *ngFor="let targetPlatformKey of targetPlatformsKeys" [value]="targetPlatformKey">
                        <img width="32px" height="32px" [src]="getAudienceFileIcon(targetPlatformKey)" />&nbsp;&nbsp;&nbsp;&nbsp;{{targetPlatforms[targetPlatformKey]}}
                    </mat-button-toggle>
                </mat-button-toggle-group>                        
            </div>       
            <!-- Platform specific attributes -->
            <div class="row">
                <div class="col-md-12"> 
                    <app-custom-segment-attributes *ngIf="+form.controls.targetPlatform.value == targetPlatforms.WAMProxy" 
                        [form]="form.controls.customSegmentAttributes" [updateValueAndValidity]="updateValueAndValidity">        
                    </app-custom-segment-attributes>
                    <app-meta-attributes *ngIf="+form.controls.targetPlatform.value == targetPlatforms.Meta" 
                        [form]="form.controls.metaAttributes" [erisAudienceName]="audience.name" [updateValueAndValidity]="updateValueAndValidity">        
                    </app-meta-attributes>
                    <app-amazon-attributes *ngIf="+form.controls.targetPlatform.value == targetPlatforms.Amazon"
                        [form]="form.controls.amazonAttributes" [erisAudienceName]="audience.name" [updateValueAndValidity]="updateValueAndValidity">        
                    </app-amazon-attributes>
                    <app-taptap-attributes *ngIf="+form.controls.targetPlatform.value == targetPlatforms.TapTap"
                        [form]="form.controls.tapTapAttributes" [erisAudienceName]="tapTapAudienceName" [updateValueAndValidity]="updateValueAndValidity">        
                    </app-taptap-attributes>                    
                    <app-xandr-attributes *ngIf="+form.controls.targetPlatform.value == targetPlatforms.Xandr"
                        [form]="form.controls.xandrAttributes" [erisSegmentName]="audience.name" [updateValueAndValidity]="updateValueAndValidity">        
                    </app-xandr-attributes>                     
                </div> 
            </div>
            <div class="row">&nbsp;</div>
            <div class="row" *ngIf="availableIdGraphTypesSelections.length > 0">
                <div class="col-md-12">
                    <label>Select the identifiers for file:</label>
                    <div [formGroup]="form.controls.targetIdentifiers" class="form-group">            
                        <ng-container *ngFor="let idGraphTypesSelection of availableIdGraphTypesSelections">
                            <input type="checkbox" [formControlName]="idGraphTypes[idGraphTypesSelection.idGraphTypeKey]" (change)="form.updateValueAndValidity()"/>
                            <label [for]="idGraphTypes[idGraphTypesSelection.idGraphTypeKey]">
                                &nbsp;&nbsp;{{idGraphTypes[idGraphTypesSelection.idGraphTypeKey]}} ({{idGraphTypesSelection.count | number:'':'es'}} identifiers)
                            </label>          
                            <br/>      
                        </ng-container>
                    </div>           
                </div>
            </div> 
        </div>
    </div>
    <div class="row">&nbsp;</div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onAccept()" [disabled]="form.invalid">Accept</button>    
    <button type="button" class="btn btn-secondary" (click)="onDismiss()">Cancel</button>
</div>
<!--
<div>
    form.status: {{form.status}} - form.invalid: {{form.invalid}}
    <br>
    form.controls.customSegmentAttributes.status: {{form.controls.customSegmentAttributes.status}} - form.controls.customSegmentAttributes.invalid: {{form.controls.customSegmentAttributes.invalid}}
    <br>
    form.controls.metaAttributes.status: {{form.controls.metaAttributes.status}} - form.controls.metaAttributes.invalid: {{form.controls.metaAttributes.invalid}}
    <br>
    form.controls.amazonAttributes.status: {{form.controls.amazonAttributes.status}} - form.controls.amazonAttributes.invalid: {{form.controls.amazonAttributes.invalid}}
    <br>
    form.controls.tapTapAttributes.status: {{form.controls.tapTapAttributes.status}} - form.controls.tapTapAttributes.invalid: {{form.controls.tapTapAttributes.invalid}}
    <br>
    form.controls.targetPlatform.status: {{form.controls.targetPlatform.status}} - form.controls.targetPlatform.invalid: {{form.controls.targetPlatform.invalid}}
    <br>
    form.controls.targetIdentifiers.status: {{form.controls.targetIdentifiers.status}} - form.controls.targetIdentifiers.invalid: {{form.controls.targetIdentifiers.invalid}}  
</div>
-->