import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgReduxModule } from '@angular-redux/store';
import { NgRedux, DevToolsExtension } from '@angular-redux/store';
import { rootReducer, ArchitectUIState } from './ThemeOptions/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { AppRoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AsyncPipe } from '@angular/common';

// BOOTSTRAP COMPONENTS

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ChartsModule } from 'ng2-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// LAYOUT

import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';
import { PageTitleComponent } from './Layout/Components/page-title/page-title.component';

// HEADER

import { HeaderComponent } from './Layout/Components/header/header.component';
import { SearchBoxComponent } from './Layout/Components/header/elements/search-box/search-box.component';
import { UserBoxComponent } from './Layout/Components/header/elements/user-box/user-box.component';

// SIDEBAR

import { SidebarComponent } from './Layout/Components/sidebar/sidebar.component';
import { LogoComponent } from './Layout/Components/sidebar/elements/logo/logo.component';

// FOOTER

import { FooterComponent } from './Layout/Components/footer/footer.component';

// DEMO PAGES

// Dashboards

import { AnalyticsComponent } from './DemoPages/Dashboards/analytics/analytics.component';

// Pages

import { ForgotPasswordBoxedComponent } from './DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component';
import { LoginBoxedComponent } from './DemoPages/UserPages/login-boxed/login-boxed.component';
import { RegisterBoxedComponent } from './DemoPages/UserPages/register-boxed/register-boxed.component';

// Elements

import { StandardComponent } from './DemoPages/Elements/Buttons/standard/standard.component';
import { DropdownsComponent } from './DemoPages/Elements/dropdowns/dropdowns.component';
import { CardsComponent } from './DemoPages/Elements/cards/cards.component';
import { ListGroupsComponent } from './DemoPages/Elements/list-groups/list-groups.component';
import { TimelineComponent } from './DemoPages/Elements/timeline/timeline.component';
import { IconsComponent } from './DemoPages/Elements/icons/icons.component';

// Components

import { AccordionsComponent } from './DemoPages/Components/accordions/accordions.component';
import { TabsComponent } from './DemoPages/Components/tabs/tabs.component';
import { CarouselComponent } from './DemoPages/Components/carousel/carousel.component';
import { ModalsComponent } from './DemoPages/Components/modals/modals.component';
import { ProgressBarComponent } from './DemoPages/Components/progress-bar/progress-bar.component';
import { PaginationComponent } from './DemoPages/Components/pagination/pagination.component';
import { TooltipsPopoversComponent } from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';

// Tables

import { RegularComponent } from './DemoPages/Tables/regular/regular.component';
import { TablesMainComponent } from './DemoPages/Tables/tables-main/tables-main.component';

// Widgets

import { ChartBoxes3Component } from './DemoPages/Widgets/chart-boxes3/chart-boxes3.component';

// Forms Elements

import { ControlsComponent } from './DemoPages/Forms/Elements/controls/controls.component';
import { LayoutComponent } from './DemoPages/Forms/Elements/layout/layout.component';

// Charts

import { ChartjsComponent } from './DemoPages/Charts/chartjs/chartjs.component';

// Chart.js Examples 

import { LineChartComponent } from './DemoPages/Charts/chartjs/examples/line-chart/line-chart.component';
import { BarChartComponent } from './DemoPages/Charts/chartjs/examples/bar-chart/bar-chart.component';
import { ScatterChartComponent } from './DemoPages/Charts/chartjs/examples/scatter-chart/scatter-chart.component';
import { RadarChartComponent } from './DemoPages/Charts/chartjs/examples/radar-chart/radar-chart.component';
import { PolarAreaChartComponent } from './DemoPages/Charts/chartjs/examples/polar-area-chart/polar-area-chart.component';
import { BubbleChartComponent } from './DemoPages/Charts/chartjs/examples/bubble-chart/bubble-chart.component';
import { DynamicChartComponent } from './DemoPages/Charts/chartjs/examples/dynamic-chart/dynamic-chart.component';
import { DoughnutChartComponent } from './DemoPages/Charts/chartjs/examples/doughnut-chart/doughnut-chart.component';
import { PieChartComponent } from './DemoPages/Charts/chartjs/examples/pie-chart/pie-chart.component';
import { NonLoggedComponent } from './pages/core/non-logged/non-logged.component';
import { FileUploadComponent } from './pages/segments/file-upload/file-upload.component';
import { DndDirective } from './directives/dnd.directive';
import { SegmentsListComponent } from './pages/segments/segments-list/segments-list.component';
import { AudiencesBuilderComponent } from './pages/audiences/deprecated/audiences-builder/audiences-builder.component';
import { AudiencesListComponent } from './pages/audiences/audiences-list/audiences-list.component';
import { InsightsComponent } from './pages/insights/insights/insights.component';

// MSAL
import { MsalModule, MsalService, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent, MsalGuardConfiguration } from "@azure/msal-angular";
import { PublicClientApplication, InteractionType, BrowserCacheLocation } from "@azure/msal-browser";
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from 'src/environments/environment';

// QUERY BUILDER
import { QueryBuilderModule } from "angular2-query-builder";

//
import { DndModule } from 'ngx-drag-drop';

// EPSILON COMPONENTS
import { DialogComponent } from './components/core/dialog/dialog.component';
import { SegmentInsightsComponent } from './pages/segments/segment-insights/segment-insights.component';
import { RootComponent } from './pages/core/root/root.component';
import { CompanySelectorDialogComponent } from './components/dialogs/core/company-selector-dialog/company-selector-dialog.component';
import { EpsilonSpinnerComponent } from './components/core/epsilon-spinner/epsilon-spinner.component';
import { CirclePackComponent } from './components/core/circle-pack/circle-pack.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InputNameDialogComponent } from './components/core/input-name-dialog/input-name-dialog.component';
import { InsightsContextualInterestsComponent } from './components/blocks/insights/insights-contextual-interests/insights-contextual-interests.component';
import { InsightsMobileAppsInterestsComponent } from './components/blocks/insights/insights-mobile-apps-interests/insights-mobile-apps-interests.component';
import { InsightsProfileUpdatesComponent } from './components/blocks/insights/insights-profile-updates/insights-profile-updates.component';
import { InsightsPurchaseIntentsComponent } from './components/blocks/insights/insights-purchase-intents/insights-purchase-intents.component';
import { InsightsIdgraphCountsComponent } from './components/blocks/insights/insights-idgraph-counts/insights-idgraph-counts.component';
import { InsightsGenderPerAgeRangesComponent } from './components/blocks/insights/insights-gender-per-age-ranges/insights-gender-per-age-ranges.component';
import { InsightsParentalStatusPerHouseholdSizesComponent } from './components/blocks/insights/insights-parental-status-per-household-sizes/insights-parental-status-per-household-sizes.component';
import { NgBytesPipeModule } from 'angular-pipes';
import { LookAlikesListComponent } from './pages/look-alikes/look-alikes-list/look-alikes-list.component';
import { NewLookAlikeComponent } from './pages/look-alikes/new-look-alike/new-look-alike.component';
import { TargetPlatformSelectorDialogComponent } from './components/dialogs/audiences/target-platform-selector-dialog/target-platform-selector-dialog.component';
import { MatchingParametersDialogComponent } from './components/blocks/segments/matching-parameters-dialog/matching-parameters-dialog.component';
import { PublicisTableComponent } from './components/core/publicis-table/publicis-table.component';

// PowerBI client
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { CustomInsightsComponent } from './pages/insights/custom-insights/custom-insights.component';
import { NoRightsComponent } from './pages/core/no-rights/no-rights.component';
import { AudienceParametersDialogComponent } from './components/dialogs/audiences/audience-parameters-dialog/audience-parameters-dialog.component';
import { CustomSegmentAttributesComponent } from './components/blocks/audiences/custom-segment-attributes/custom-segment-attributes.component';
import { AdminDataTransfersComponent } from './pages/admin/data-transfers/admin-data-transfers.component';
import { DataTransferInfoDialogComponent } from './components/dialogs/admin/data-transfer-info-dialog/data-transfer-info-dialog.component';
import { AdminDataTransfersReportComponent } from './pages/admin/data-transfers-report/admin-data-transfers-report.component';
import { RootAdminComponent } from './pages/core/root-admin/root-admin.component';
import { AudiencesSchedulerComponent } from './pages/audiences/audiences-scheduler/audiences-scheduler.component';
import { AudienceSchedulingParametersDialogComponent } from './components/dialogs/audiences/audience-scheduling-parameters-dialog/audience-scheduling-parameters-dialog.component';
import { AudienceInfoDialogComponent } from './components/dialogs/audiences/audience-info-dialog/audience-info-dialog.component';
import { AudiencesBuilderTransactionalComponent } from './pages/audiences/deprecated/audiences-builder-transactional/audiences-builder-transactional.component';
import { AdminUsersComponent } from './pages/admin/users/admin-users.component';
import { AdB2cUserSelectorDialogComponent } from './components/dialogs/admin/ad-b2c-user-selector-dialog/ad-b2c-user-selector-dialog.component';
import { AdminProcessesComponent } from './pages/admin/processes/admin-processes.component';
import { AudienceSelectorDialogComponent } from './components/dialogs/audiences-builder/audience-selector-dialog/audience-selector-dialog.component';
import { UserSelectorDialogComponent } from './components/dialogs/admin/user-selector-dialog/user-selector-dialog.component';
import { AdminCompaniesComponent } from './pages/admin/companies/admin-companies.component';
import { GenericIdNameSelectorDialogComponent } from './components/core/generic-id-name-selector-dialog/generic-id-name-selector-dialog.component';
import { CompanyLogoUploadDialogComponent } from './components/dialogs/admin/company-logo-upload-dialog/company-logo-upload-dialog.component';
import { AdminPowerBIReportsComponent } from './pages/admin/powerbi-reports/admin-powerbi-reports.component';
import { PowerBIReportParametersDialogComponent } from './components/dialogs/admin/powerbi-report-parameters-dialog/powerbi-report-parameters-dialog.component';
import { IABMasterMaintenanceComponent } from './pages/admin/iab-master-maintenance/iab-master-maintenance.component';
import { IabMasterTreeComponent } from './components/blocks/admin/iab-master-tree/iab-master-tree.component';
import { IabMasterSelectorDialogComponent } from './components/dialogs/admin/iab-master-selector-dialog/iab-master-selector-dialog.component';
import { MetaAttributesComponent } from './components/blocks/audiences/meta-attributes/meta-attributes.component';
import { TaxonomyMaintenanceComponent } from './pages/admin/taxonomy-maintenance/taxonomy-maintenance.component';
import { AudiencesBuilderV2Component } from './pages/audiences/audiences-builder-v2/audiences-builder-v2.component';
import { AudienceRuleBuilderDialogComponent } from './components/dialogs/audiences-builder/audience-rule-builder-dialog/audience-rule-builder-dialog.component';
import { AttributeValueDateSelectorDialogComponent } from './components/dialogs/audiences-builder/attribute-value-date-selector-dialog/attribute-value-date-selector-dialog.component';
import { AttributeValueAffinitySelectorDialogComponent } from './components/dialogs/audiences-builder/attribute-value-affinity-selector-dialog/attribute-value-affinity-selector-dialog.component';
import { DialogCustomButtonsComponent } from './components/core/dialog-custom-buttons/dialog-custom-buttons.component';
import { TaxonomyItemSelectorDialogComponent } from './components/dialogs/admin/taxonomy-item-selector-dialog/taxonomy-item-selector-dialog.component';
import { IngestionProgressComponent } from './components/blocks/admin/ingestion-progress/ingestion-progress.component';
import { MaintenanceProcessesComponent } from './components/blocks/admin/maintenance-processes/maintenance-processes.component';
import { ManualProcessesComponent } from './components/blocks/admin/manual-processes/manual-processes.component';
import { AdminQAReportsComponent } from './pages/admin/qa-reports/admin-qa-reports.component';
import { ReportViewerCitiserviComponent } from './components/blocks/admin/report-viewer-citiservi/report-viewer-citiservi.component';
import { ReportViewerWeboramaComponent } from './components/blocks/admin/report-viewer-weborama/report-viewer-weborama.component';
import { ReportViewerLoganComponent } from './components/blocks/admin/report-viewer-logan/report-viewer-logan.component';
import { ReportViewerFintonicComponent } from './components/blocks/admin/report-viewer-fintonic/report-viewer-fintonic.component';
import { AttributeValueAudienceOverlapSelectorDialogComponent } from './components/dialogs/audiences-builder/attribute-value-audience-overlap-selector-dialog/attribute-value-audience-overlap-selector-dialog.component';
import { ReportViewerAfileadComponent } from './components/blocks/admin/report-viewer-afilead/report-viewer-afilead.component';
import { ReportViewerCarrefourComponent } from './components/blocks/admin/report-viewer-carrefour/report-viewer-carrefour.component';
import { AudienceTagsDialogComponent } from './components/dialogs/audiences/audience-tags-dialog/audience-tags-dialog.component';
import { AudienceListFilterDialogComponent } from './components/dialogs/audiences/audience-list-filter-dialog/audience-list-filter-dialog.component';
import { PlatformMaintenanceComponent } from './pages/core/platform-maintenance/platform-maintenance.component';
import { AmazonAttributesComponent } from './components/blocks/audiences/amazon-attributes/amazon-attributes.component';
import { NielsenDataComponent } from './pages/insights/nielsen-data/nielsen-data.component';
import { ErisTreeComponent } from './components/core/eris-tree/eris-tree.component';
import { TaptapAttributesComponent } from './components/blocks/audiences/taptap-attributes/taptap-attributes.component';
import { XandrAttributesComponent } from './components/blocks/audiences/xandr-attributes/xandr-attributes.component';
import { SqlRunnerComponent } from './pages/admin/utils/sql-runner/sql-runner.component';
import { CodeEditorModule } from '@ngstack/code-editor';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  useBothWheelAxes: true
};
const protectedResourceMap = new Map<string, Array<string>>();
protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read"]);

@NgModule({
  declarations: [

    // LAYOUT

    AppComponent,
    BaseLayoutComponent,
    PagesLayoutComponent,
    PageTitleComponent,

    // HEADER

    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent,

    // DEMO PAGES

    // Dashboards

    AnalyticsComponent,

    // User Pages

    ForgotPasswordBoxedComponent,
    LoginBoxedComponent,
    RegisterBoxedComponent,

    // Elements

    StandardComponent,
    IconsComponent,
    DropdownsComponent,
    CardsComponent,
    ListGroupsComponent,
    TimelineComponent,

    // Components

    AccordionsComponent,
    TabsComponent,
    CarouselComponent,
    ModalsComponent,
    ProgressBarComponent,
    PaginationComponent,
    TooltipsPopoversComponent,

    // Tables

    RegularComponent,
    TablesMainComponent,

    // Dashboard Boxes

    ChartBoxes3Component,

    // Form Elements

    ControlsComponent,
    LayoutComponent,

    // CHARTS

    ChartjsComponent,

    // Chart.js Examples

    LineChartComponent,
    BarChartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    PieChartComponent,
    PolarAreaChartComponent,
    DynamicChartComponent,
    BubbleChartComponent,
    ScatterChartComponent,
    NonLoggedComponent,
    FileUploadComponent,
    DndDirective,
    SegmentsListComponent,
    AudiencesBuilderComponent,
    AudiencesListComponent,
    InsightsComponent,
    DialogComponent,
    SegmentInsightsComponent,
    RootComponent,
    CompanySelectorDialogComponent,
    EpsilonSpinnerComponent,
    CirclePackComponent,
    InputNameDialogComponent,
    InsightsContextualInterestsComponent,
    InsightsMobileAppsInterestsComponent,
    InsightsProfileUpdatesComponent,
    InsightsPurchaseIntentsComponent,
    InsightsIdgraphCountsComponent,
    InsightsGenderPerAgeRangesComponent,
    InsightsParentalStatusPerHouseholdSizesComponent,
    LookAlikesListComponent,
    NewLookAlikeComponent,
    TargetPlatformSelectorDialogComponent,
    MatchingParametersDialogComponent,
    PublicisTableComponent,
    CustomInsightsComponent,
    NoRightsComponent,
    AudienceParametersDialogComponent,
    CustomSegmentAttributesComponent,
    AdminDataTransfersComponent,
    DataTransferInfoDialogComponent,
    AdminDataTransfersReportComponent,
    RootAdminComponent,
    AudiencesSchedulerComponent,
    AudienceSchedulingParametersDialogComponent,
    AudienceInfoDialogComponent,
    AudiencesBuilderTransactionalComponent,
    AdminUsersComponent,
    AdB2cUserSelectorDialogComponent,
    AdminProcessesComponent,
    AudienceSelectorDialogComponent,
    UserSelectorDialogComponent,
    AdminCompaniesComponent,
    GenericIdNameSelectorDialogComponent,
    CompanyLogoUploadDialogComponent,
    AdminPowerBIReportsComponent,
    PowerBIReportParametersDialogComponent,
    IABMasterMaintenanceComponent,
    IabMasterTreeComponent,
    IabMasterSelectorDialogComponent,
    MetaAttributesComponent,
    TaxonomyMaintenanceComponent,
    AudiencesBuilderV2Component,
    AudienceRuleBuilderDialogComponent,
    AttributeValueDateSelectorDialogComponent,
    AttributeValueAffinitySelectorDialogComponent,
    DialogCustomButtonsComponent,
    TaxonomyItemSelectorDialogComponent,
    IngestionProgressComponent,
    MaintenanceProcessesComponent,
    ManualProcessesComponent,
    AdminQAReportsComponent,
    ReportViewerCitiserviComponent,
    ReportViewerWeboramaComponent,
    ReportViewerLoganComponent,
    ReportViewerFintonicComponent,
    AttributeValueAudienceOverlapSelectorDialogComponent,
    ReportViewerAfileadComponent,
    ReportViewerCarrefourComponent,
    AudienceTagsDialogComponent,
    AudienceListFilterDialogComponent,
    PlatformMaintenanceComponent,
    AmazonAttributesComponent,
    NielsenDataComponent,
    ErisTreeComponent,
    TaptapAttributesComponent,
    XandrAttributesComponent,
    SqlRunnerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    
    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Material
    MatSliderModule,
    MatButtonModule,
    MatIconModule,
    MatTreeModule,
    MatDatepickerModule, 
    MatNativeDateModule,
    MatTabsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatSelectModule,
    MatMenuModule,
    MatDividerModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    
    // Custom pipes
    NgBytesPipeModule,

    // Charts
    ChartsModule,

    // MSAL
    MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
      auth: {
        clientId: environment.clientId,
        authority: "https://publicisid.b2clogin.com/publicisid.onmicrosoft.com/B2C_1_PublicisIdLogin",
        knownAuthorities: ["publicisid.b2clogin.com"],
        redirectUri: environment.baseUri,
        postLogoutRedirectUri: environment.baseUri,
        navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          loggerCallback: () => { },
          piiLoggingEnabled: false
        }
      }
    }), {
      interactionType: InteractionType.Popup, // MSAL Guard Configuration
      authRequest: {
        scopes: environment.authScopes
      },
      loginFailedRoute: "/login-failed"
    }, {
      interactionType: InteractionType.Popup, // MSAL Interceptor Configuration
      protectedResourceMap
    }
    ),
    // Query builder
    QueryBuilderModule,

    // NGX Drag & Drop
    DndModule,

    // PowerBI client
    PowerBIEmbedModule,

    // Code editor
    CodeEditorModule.forRoot()

  ],
  providers: [
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard,
    DatePipe,
    DecimalPipe,
    AsyncPipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
  };
}
