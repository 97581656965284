import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../core/http.service';
import { Observable } from 'rxjs';
import { GlobalsService } from '../globals.service';

@Injectable({
  providedIn: 'root'
})
export class A3mediaAudiencesService {

  constructor(
    private httpService: HttpService,
    private globalsService: GlobalsService
  ) { }

  public getAudiences(): Observable<string[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/a3media/audiences`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }  

}