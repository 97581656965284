import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErisProcess } from 'src/app/models/services/admin/erisProcess';
import { ErisProcessesInfo } from 'src/app/models/services/admin/erisProcessesInfo';
import { DialogService } from 'src/app/services/core/dialog.service';
import { MonitorService } from 'src/app/services/monitor.service';
import { UsersService } from 'src/app/services/users.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-admin-processes',
  templateUrl: './admin-processes.component.html',
  styleUrls: ['./admin-processes.component.sass']
})
export class AdminProcessesComponent implements OnInit, OnDestroy {

  heading: string = 'Processes';
  subheading: string = '';
  icon: string = 'pe-7s-config icon-gradient bg-midnight-bloom';
  loadFinished: boolean = false;

  erisProcessesInfo: ErisProcessesInfo;

  constructor(
    public usersService: UsersService,
    private globals: ThemeOptions
  ) { 
  }

  ngOnInit(): void {
    this.globals.adminMode = true;
  }

  ngOnDestroy(): void {
    this.globals.adminMode = false;
  }

}
