<div class="card">
    <div class="card-body">
        <ngx-spinner name="dialogSpinner" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p style="color: white">Loading ...</p>
        </ngx-spinner>        
        <div class="row">
            <div class="col-md-12">
                <h3>Movistar audience parameters</h3>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Audience name</mat-label>
                        <input type="text" placeholder="Ex. 'PID_'" aria-label="Audience name" matInput 
                        [formControl]="form.controls.audienceName"
                        (input)="onAudienceNameChange()" style="width: 100%;">       
                        <mat-error *ngIf="form.controls.audienceName.errors && (form.controls.audienceName.dirty || form.controls.audienceName.touched)">Audience name is required</mat-error>
                    </mat-form-field>                    
                </div>
            </div> 
        </form>
    </div>
</div>