import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../core/http.service';
import { Observable } from 'rxjs';
import { AudienceQueryCountRequest, AudienceQueryCountResponse } from '../../models/services/audienceQueryCount';
import { CreateAudienceRequest } from '../../models/services/createAudience';
import { Audience, AudienceCategory, AudienceFile, AudiencesPage, AudiencesPageParameters } from '../../models/services/audience';
import { WamCustomSegment } from '../../models/services/wamCustomSegment';
import { UpdateAudienceRequest } from '../../models/services/updateAudience';
import { GlobalsService } from '../globals.service';
import { IdGraphTypes } from '../../models/services/idGraphTypes.enum';
import { Tag } from '../../models/services/tag';

@Injectable({
  providedIn: 'root'
})
export class AudiencesService {

  constructor(
    private httpService: HttpService,
    private globalsService: GlobalsService
  ) { }

  public getAudienceCounts(request: AudienceQueryCountRequest): Observable<AudienceQueryCountResponse> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/count`;
    return this.httpService.post(url, request, this.globalsService.getActiveCompany()?.id);
  }

  public getAudience(audienceId: number): Observable<Audience> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }

  public getAudiences(): Observable<Audience[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id, true);
  }

  public getAudiencesPage(pageParameters: AudiencesPageParameters): Observable<AudiencesPage> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/page`;
    return this.httpService.post(url, pageParameters, this.globalsService.getActiveCompany()?.id, true);
  }

  public createAudience(request: CreateAudienceRequest): Observable<void> {
      let url: string = `${environment.audiencesServicesApiBaseUri}/audiences`;    
      return this.httpService.postNoResponse(url, request, this.globalsService.getActiveCompany()?.id);  
  }

  public updateAudience(audienceId: number, request: UpdateAudienceRequest): Observable<void> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}`;
    return this.httpService.putNoResponse(url, request, this.globalsService.getActiveCompany()?.id);
  }

  public removeAudience(audienceId: number): Observable<Audience[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}`;
    return this.httpService.delete(url, this.globalsService.getActiveCompany()?.id);
  }

  public archiveAudience(audienceId: number): Observable<void> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}/archive`;
    return this.httpService.postNoResponse(url, null, this.globalsService.getActiveCompany()?.id);
  }

  public refreshAudience(audienceId: number): Observable<void> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}/refresh`;
    return this.httpService.postNoResponse(url, null, this.globalsService.getActiveCompany()?.id);
  }

  public sendAudience(audienceId: number, parameters: AudienceFile): Observable<void> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}/send`;
    return this.httpService.postNoResponse(url, parameters, this.globalsService.getActiveCompany()?.id);
  }
  
  public createAudienceFile(audienceId: number, audienceFile: AudienceFile): Observable<void> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}/file`;
    return this.httpService.postNoResponse(url, audienceFile, this.globalsService.getActiveCompany()?.id);
  }

  public downloadAudienceFile(audienceId: number, audienceFileId: number): Observable<Blob> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}/file/${audienceFileId}`;
    return this.httpService.getFile(url, this.globalsService.getActiveCompany()?.id);
  }

  public removeAudienceFile(audienceId: number, audienceFileId: number): Observable<AudienceFile[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}/file/${audienceFileId}`;
    return this.httpService.delete(url, this.globalsService.getActiveCompany()?.id);
  }

  public sendAudienceFile(audienceId: number, audienceFileId: number): Observable<void> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}/file/${audienceFileId}/send`;
    return this.httpService.postNoResponse(url, null, this.globalsService.getActiveCompany()?.id);
  }

  public getAudiencesCategories(): Observable<AudienceCategory[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiencesCategories`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }

  public getWamCustomSegments(): Observable<WamCustomSegment[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/wam/customSegments`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id);
  }  

  public updateScheduling(audienceId: number, schedulingId: number, endDate?: Date): Observable<void> {
    let url: string;
    if (!!endDate)
      url = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}/scheduling/${schedulingId}/${endDate.toISOString()}`;
    else
      url = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}/scheduling/${schedulingId}`;
    
    return this.httpService.putNoResponse(url, null, this.globalsService.getActiveCompany()?.id);
  }    

  public moveAudience(audienceId: number, targetCompanyId: number): Observable<void> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}/move/${targetCompanyId}`;
    return this.httpService.postNoResponse(url, null, this.globalsService.getActiveCompany()?.id);
  }    

  public getIdGraphTypeDescription = (idGraphTypeId: number) => {
    switch (idGraphTypeId) {
      case IdGraphTypes.HashedEmail:
        return "Hashed emails";
      case IdGraphTypes.WAMCookieEncrypted:
        return "WAM cookies (encrypted)";
      case IdGraphTypes.WAMCookie:
        return "WAM cookies";
      case IdGraphTypes.MAID:
        return "MAIDs";
      case IdGraphTypes.XandrCookie:
        return "Xandr cookies";
      case IdGraphTypes.TTDCookie:
        return "TheTradeDesk cookies";        
      default:
        return "Unknown";
    };  
  }

  public getAllTags(): Observable<Tag[]> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/tags`;
    return this.httpService.get(url, this.globalsService.getActiveCompany()?.id, true);
  }  

  public updateTags(audienceId: number, tags: Tag[]): Observable<void> {
    let url: string = `${environment.audiencesServicesApiBaseUri}/audiences/${audienceId}/tags`;
    return this.httpService.putNoResponse(url, tags, this.globalsService.getActiveCompany()?.id);
  } 
}
