<div class="card">
    <div class="card-body">
        <ngx-spinner name="dialogSpinner" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p style="color: white">Loading ...</p>
        </ngx-spinner>        
        <div class="row">
            <div class="col-md-12">
                <h3>Amazon audience parameters</h3>
                <section>
                    <mat-button-toggle-group [(ngModel)]="newAudience" name="newAudienceSelector" aria-label="New audience" (change)="onNewAudienceSelected()">
                        <mat-button-toggle value="0">Existing audience</mat-button-toggle>
                        <mat-button-toggle value="1">New audience</mat-button-toggle>
                    </mat-button-toggle-group>        
                </section>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-3">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Profile</mat-label>
                        <input #matAutocompleteProfiles name="matAutocompleteProfiles" type="text" placeholder="Pick one" aria-label="Profile" matInput 
                            [formControl]="form.controls.profileId" [matAutocomplete]="auto" 
                            (input)="filterProfiles()" (focus)="filterProfiles()" (click)="resetProfilesFilter()">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getProfileDescription.bind(this)" (optionSelected)="onProfileChange($event)">
                            <mat-option *ngFor="let profile of filteredProfiles" [value]="profile.profileId">{{profile.accountInfo.name}}</mat-option>
                        </mat-autocomplete>                    
                        <mat-error *ngIf="form.controls.profileId.errors && (form.controls.profileId.dirty || form.controls.profileId.touched)">Profile is required</mat-error>
                    </mat-form-field>   
                </div>
                <div class="col-md-3">
                    <mat-form-field *ngIf="advertisers.length > 0" style="width: 100%;">
                        <mat-label>Advertiser</mat-label>
                        <input #matAutocompleteAdvertisers name="matAutocompleteAdvertisers" type="text" placeholder="Pick one" aria-label="Audience" matInput 
                        [formControl]="form.controls.advertiserId" [matAutocomplete]="auto"
                        (input)="filterAdvertisers()" (focus)="filterAdvertisers()" (click)="resetAdvertisersFilter()">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getAdvertiserDescription.bind(this)" (optionSelected)="onAdvertiserChange($event)">
                            <mat-option *ngFor="let advertiser of filteredAdvertisers" [value]="advertiser.advertiserId">{{advertiser.name}}</mat-option>
                        </mat-autocomplete>                    
                        <mat-error *ngIf="form.controls.advertiserId.errors && (form.controls.advertiserId.dirty || form.controls.advertiserId.touched)">Advertiser is required</mat-error>
                    </mat-form-field>   
                </div>
                <div class="col-md-6" *ngIf="newAudience == '0'">
                    <mat-form-field *ngIf="!!form.controls.advertiserId.value && audiences.length > 0" style="width: 100%;">
                        <mat-label>Audience</mat-label>
                        <input #matAutocompleteAudiences name="matAutocompleteAudiences" type="text" placeholder="Pick one" aria-label="Audience" matInput 
                        [formControl]="form.controls.audienceId" [matAutocomplete]="auto"
                        (input)="filterAudiences()" (focus)="filterAudiences()" (click)="resetAudiencesFilter()"
                        style="width: 100%;">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getAudienceDescription.bind(this)" (optionSelected)="onAudienceChange($event)">
                            <mat-option *ngFor="let audience of filteredAudiences" [value]="audience.audienceId">{{audience.audienceId}} - {{audience.audienceName}}</mat-option>
                        </mat-autocomplete>                      
                        <mat-error *ngIf="form.controls.audienceId.errors && (form.controls.audienceId.dirty || form.controls.audienceId.touched)">Audience is required</mat-error>                        
                    </mat-form-field>   
                    <mat-error *ngIf="!!form.controls.advertiserId.value && audiences.length == 0" style="width: 100%;">The advertiser has no AMC audiences</mat-error>
                </div>
            </div>      
        </form>
    </div>
</div>
