import { Component, OnInit } from '@angular/core';
import { Audience, AudienceComment } from 'src/app/models/services/audience';
import { AudiencesService } from 'src/app/services/audiences/audiences.service';
import { DialogService } from 'src/app/services/core/dialog.service';

@Component({
  selector: 'app-audience-info-dialog',
  templateUrl: './audience-info-dialog.component.html',
  styleUrls: ['./audience-info-dialog.component.sass']
})
export class AudienceInfoDialogComponent implements OnInit {
  public dismiss: () => void;
  public audience: Audience;

  title: string = "Audience info"; 

  private flatten = (x) => x.reduce((a, b) => a.concat(Array.isArray(b) ? this.flatten(b) : b), []);

  constructor(
    private audiencesService: AudiencesService,
    private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  deleteComment = (comment: AudienceComment): void => {
    this.dialogService.showConfirmation('Are you sure to remove comment?', 
      () => {
        this.audiencesService.removeComment(comment.audienceId, comment.id).subscribe(
          () => {
            let commentIndex: number = this.audience?.comments?.findIndex(x => x.id == comment.id);
            this.audience?.comments?.splice(commentIndex, 1);
          });
      },
      () => { });
  }
}


