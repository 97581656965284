import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-text-area-field-dialog',
  templateUrl: './text-area-field-dialog.component.html',
  styleUrls: ['./text-area-field-dialog.component.sass']
})
export class TextAreaFieldDialogComponent implements OnInit {

  form: FormGroup;
  fieldValue: string;
  fieldName: string;
  title: string;
  message: string;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group(
      {
        fieldValue: ['', Validators.required]
      });
  }

  ngOnInit(): void {
  }

  public accept: (fieldValue: string) => void;
  public dismiss: () => void;
}
