import { AudienceFile } from 'src/app/models/services/audience';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Audience } from 'src/app/models/services/audience';
import { AudiencesFilesAttributes } from 'src/app/models/services/audiencesFilesAttributes.enum';
import { AudiencesFilesTargetPlatforms } from 'src/app/models/services/audiencesFilesTargetPlatforms.enum';
import { IdGraphTypes } from 'src/app/models/services/idGraphTypes.enum';
import { UsersService } from 'src/app/services/users.service';
import { AtLeastOneChecked } from 'src/app/services/core/validators/atLeastOne-validator';

export class IdGrapTypeSelection {
  idGraphTypeKey: number;
  count: number;
}

@Component({
  selector: 'app-target-platform-selector-dialog',
  templateUrl: './target-platform-selector-dialog.component.html',
  styleUrls: ['./target-platform-selector-dialog.component.sass']
})
export class TargetPlatformSelectorDialogComponent implements OnInit {
  public audience: Audience;
  public send: boolean;
  public accept: (data: AudienceFile) => void;
  public dismiss: () => void;

  tapTapAudienceName: string;
  tapTapAudienceCategoryName: string;

  form: FormGroup;
  name: string;
  title: string = ""; 
  targetPlatforms: any = AudiencesFilesTargetPlatforms;
  targetPlatformsKeys: number[];
  availableIdGraphTypesSelections: IdGrapTypeSelection[];
  idGraphTypes: any = IdGraphTypes;

  constructor(
    private formBuilder: FormBuilder,
    private usersService: UsersService
  ) {

  }

  ngOnInit(): void {
    this.title = this.send ? "Send audience" : "Create audience file";
    let isAdmin: boolean = this.usersService.hasGlobalSectionRight('Admin');
    this.targetPlatformsKeys = [];
    if(this.getIdentifierCount(IdGraphTypes.WAMCookieEncrypted) + this.getIdentifierCount(IdGraphTypes.MAID) > 0 )
      this.targetPlatformsKeys.push(AudiencesFilesTargetPlatforms.WAMProxy);

    if(this.getIdentifierCount(IdGraphTypes.HashedEmail) + this.getIdentifierCount(IdGraphTypes.MAID) > 0 ) {
      this.targetPlatformsKeys.push(AudiencesFilesTargetPlatforms.Meta);
      if (!this.send)
        this.targetPlatformsKeys.push(AudiencesFilesTargetPlatforms.TikTok);
      if(isAdmin) {
        this.targetPlatformsKeys.push(AudiencesFilesTargetPlatforms.TapTap);
        this.targetPlatformsKeys.push(AudiencesFilesTargetPlatforms.Amazon);
      }
    }

    if(isAdmin && this.getIdentifierCount(IdGraphTypes.HashedEmail) > 0 ) {
      this.targetPlatformsKeys.push(AudiencesFilesTargetPlatforms.Movistar);
    }

    if(isAdmin && this.getIdentifierCount(IdGraphTypes.XandrCookie) + this.getIdentifierCount(IdGraphTypes.MAID) > 0 ) {
      this.targetPlatformsKeys.push(AudiencesFilesTargetPlatforms.Xandr);
    }

    if(isAdmin)
      this.targetPlatformsKeys.push(AudiencesFilesTargetPlatforms.TapTapOOH);          

    // Raise onChange to configure forms
    this.form = this.formBuilder.group({
      targetPlatform: [this.targetPlatformsKeys[0], Validators.required],
      customSegmentAttributes:  this.formBuilder.group({}),
      metaAttributes: this.formBuilder.group({}),
      amazonAttributes: this.formBuilder.group({}),
      tapTapAttributes: this.formBuilder.group({}),
      xandrAttributes: this.formBuilder.group({}),
      targetIdentifiers: this.formBuilder.group({})
    });

    this.onChangeTargetPlaform();
  }
    
  getIdentifierCount = (idGraphType: IdGraphTypes): number => {
    return this.audience.idGraphCounts[idGraphType] ?? 0;
  } 

  onAccept = (): void => {
    let result: AudienceFile = {
      targetPlatform: +this.form.controls.targetPlatform.value,
      customAttributes: [],
      idGraphTypes: []
    };
    switch(result.targetPlatform) {
      case AudiencesFilesTargetPlatforms.WAMProxy:
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.CustomSegmentId,
          value: this.form.get('customSegmentAttributes.customSegmentId').value
        });
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.CustomSegmentDescription,
          value: this.form.get('customSegmentAttributes.customSegmentDescription').value
        });
        break;

      case AudiencesFilesTargetPlatforms.Meta:
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.ProfileExternalIdentifier,
          value: this.form.get('metaAttributes.businessId').value
        });
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.AdvertiserExternalIdentifier,
          value: this.form.get('metaAttributes.adAccountId').value
        });
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.AudienceExternalIdentifier,
          value: this.form.get('metaAttributes.audienceId').value
        });
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.AudienceExternalName,
          value: this.form.get('metaAttributes.audienceName').value
        });    
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.AudienceSharedAccounts,
          value: JSON.stringify(this.form.get('metaAttributes.sharedAccounts').value)
        });
        break;
        
      case AudiencesFilesTargetPlatforms.Amazon:
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.AudienceExternalIdentifier,
          value: this.form.get('amazonAttributes.audienceId').value
        });
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.AudienceExternalName,
          value: this.form.get('amazonAttributes.audienceName').value
        });
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.ProfileExternalIdentifier,
          value: this.form.get('amazonAttributes.profileId').value
        });
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.AdvertiserExternalIdentifier,
          value: this.form.get('amazonAttributes.advertiserId').value
        });   
        break;    

      case AudiencesFilesTargetPlatforms.Xandr:
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.ProfileExternalIdentifier,
          value: this.form.get('xandrAttributes.accountId').value
        });              
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.AudienceExternalIdentifier,
          value: this.form.get('xandrAttributes.segmentId').value
        });
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.AudienceExternalName,
          value: this.form.get('xandrAttributes.segmentName').value
        }); 
        break; 

      case AudiencesFilesTargetPlatforms.TapTap:
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.AudienceExternalName,
          value: this.form.get('tapTapAttributes.audienceName').value
        });               
        result.customAttributes.push({
          attributeId: AudiencesFilesAttributes.AudienceExternalCategoryName,
          value: this.form.get('tapTapAttributes.categoryName').value
        });
        break;         

      default:
        break;
    }
    // IdGraphTypes
    Object.keys(this.form.get('targetIdentifiers')['controls']).filter(x => this.form.get('targetIdentifiers')['controls'][x].value).forEach(x => {
      result.idGraphTypes.push(this.idGraphTypes[x]);
    });

    this.accept(result);
  };

  public updateValueAndValidity = (): void => {
    this.form.updateValueAndValidity();
  }

  onDismiss = (): void => {
    this.dismiss();
  }

  getAudienceFileIcon = (targetPlatform: AudiencesFilesTargetPlatforms): string => {
    return `assets/images/target-platforms/${targetPlatform}.png`;
  }

  onChangeTargetPlaform = (): void => {
    switch(+this.form.controls.targetPlatform.value) {
      case AudiencesFilesTargetPlatforms.WAMProxy:        
        this.form.controls.customSegmentAttributes = this.formBuilder.group({
          customSegmentId: [''],
          customSegmentDescription: ['', Validators.required]
        });
        this.form.controls.metaAttributes = this.formBuilder.group({});
        this.form.controls.amazonAttributes = this.formBuilder.group({});
        this.form.controls.tapTapAttributes = this.formBuilder.group({});
        this.form.controls.xandrAttributes = this.formBuilder.group({});
        break;

      case AudiencesFilesTargetPlatforms.Meta:
        this.form.controls.customSegmentAttributes = this.formBuilder.group({});
        this.form.controls.metaAttributes = this.formBuilder.group({
          businessId: ['', Validators.required],
          adAccountId: ['', Validators.required],
          audienceId: [''],
          audienceName: ['', Validators.required],
          sharedAccounts: [[]]
        });
        this.form.controls.amazonAttributes = this.formBuilder.group({});
        this.form.controls.tapTapAttributes = this.formBuilder.group({});
        this.form.controls.xandrAttributes = this.formBuilder.group({});
        break;
      
      case AudiencesFilesTargetPlatforms.Amazon:
        this.form.controls.customSegmentAttributes = this.formBuilder.group({});
        this.form.controls.metaAttributes = this.formBuilder.group({});
        this.form.controls.amazonAttributes = this.formBuilder.group({
          profileId: ['', Validators.required],
          advertiserId: ['', Validators.required],
          audienceId: [''],
          audienceName: ['', Validators.required]
        });
        this.form.controls.tapTapAttributes = this.formBuilder.group({});
        this.form.controls.xandrAttributes = this.formBuilder.group({});
        break;

      case AudiencesFilesTargetPlatforms.TapTap:
        this.form.controls.customSegmentAttributes = this.formBuilder.group({});
        this.form.controls.metaAttributes = this.formBuilder.group({});
        this.form.controls.amazonAttributes = this.formBuilder.group({});

        // Find last tap tap file if exists to get audience external data
        if (!!this.audience.audienceFiles) {
          let lastTapTapFile: AudienceFile = [...this.audience.audienceFiles].sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()).find(x => x.targetPlatform == +this.form.controls.targetPlatform.value);
          if (!!lastTapTapFile) {
            this.tapTapAudienceCategoryName = lastTapTapFile.customAttributes.find(x => x.attributeId == AudiencesFilesAttributes.AudienceExternalCategoryName).value
            this.tapTapAudienceName = lastTapTapFile.customAttributes.find(x => x.attributeId == AudiencesFilesAttributes.AudienceExternalName).value
          }   
        }
        if(!this.tapTapAudienceCategoryName) {
          this.tapTapAudienceCategoryName = '';
          this.tapTapAudienceName = this.audience.name;
        }

        this.form.controls.tapTapAttributes = this.formBuilder.group({
          audienceName: [this.tapTapAudienceName, Validators.required],
          categoryName: [this.tapTapAudienceCategoryName, Validators.required]
        });

        this.form.controls.xandrAttributes = this.formBuilder.group({});
        break;        

      case AudiencesFilesTargetPlatforms.Xandr:
        this.form.controls.customSegmentAttributes = this.formBuilder.group({});
        this.form.controls.metaAttributes = this.formBuilder.group({});
        this.form.controls.amazonAttributes = this.formBuilder.group({});
        this.form.controls.tapTapAttributes = this.formBuilder.group({});
        this.form.controls.xandrAttributes = this.formBuilder.group({
          accountId: ['', Validators.required],
          segmentId: [''],
          segmentName: ['', Validators.required]
        });
        break;

      default:
        this.form.controls.customSegmentAttributes = this.formBuilder.group({});
        this.form.controls.metaAttributes = this.formBuilder.group({});
        this.form.controls.amazonAttributes = this.formBuilder.group({});
        this.form.controls.tapTapAttributes = this.formBuilder.group({});
        this.form.controls.xandrAttributes = this.formBuilder.group({});

        break;
    }

    // Available identifiers    
    switch(+this.form.controls.targetPlatform.value) {
      case AudiencesFilesTargetPlatforms.WAMProxy:        
        this.availableIdGraphTypesSelections = [{
            idGraphTypeKey: IdGraphTypes.WAMCookieEncrypted,
            count: this.audience.idGraphCounts[IdGraphTypes.WAMCookieEncrypted] ?? 0
          }, {
            idGraphTypeKey: IdGraphTypes.MAID,
            count: this.audience.idGraphCounts[IdGraphTypes.MAID] ?? 0
          }
        ];
        break;

      case AudiencesFilesTargetPlatforms.Meta:
      case AudiencesFilesTargetPlatforms.TikTok:
      case AudiencesFilesTargetPlatforms.Amazon:
        this.availableIdGraphTypesSelections = [{
            idGraphTypeKey: IdGraphTypes.HashedEmail,            
            count: this.audience.idGraphCounts[IdGraphTypes.HashedEmail] ?? 0
          }, {
            idGraphTypeKey: IdGraphTypes.MAID,
            count: this.audience.idGraphCounts[IdGraphTypes.MAID] ?? 0
          }];
          break;        

      case AudiencesFilesTargetPlatforms.UnityOnboarding:
      case AudiencesFilesTargetPlatforms.Movistar:
        this.availableIdGraphTypesSelections = [{
          idGraphTypeKey: IdGraphTypes.HashedEmail,
          count: this.audience.idGraphCounts[IdGraphTypes.HashedEmail] ?? 0
        }];
        break;

      case AudiencesFilesTargetPlatforms.TapTap:
        this.availableIdGraphTypesSelections = [{
          idGraphTypeKey: IdGraphTypes.MAID,
          count: this.audience.idGraphCounts[IdGraphTypes.MAID] ?? 0
        }];
        break;

      case AudiencesFilesTargetPlatforms.Xandr:
        this.availableIdGraphTypesSelections = [{
            idGraphTypeKey: IdGraphTypes.XandrCookie,            
            count: this.audience.idGraphCounts[IdGraphTypes.XandrCookie] ?? 0
          }, {
            idGraphTypeKey: IdGraphTypes.MAID,
            count: this.audience.idGraphCounts[IdGraphTypes.MAID] ?? 0
          }];
          break;  

      default:
        this.availableIdGraphTypesSelections = []; 
        break;
    }

    var formControls = {};
    this.availableIdGraphTypesSelections.forEach((x) => {
      formControls[this.idGraphTypes[x.idGraphTypeKey]] = new FormControl({ value: x.count > 0, disabled: x.count == 0});
    });
    this.form.controls.targetIdentifiers = new FormGroup(formControls, AtLeastOneChecked);
    
    this.updateValueAndValidity();
  }

}
