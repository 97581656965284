import { Injectable } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DialogComponent } from '../../components/core/dialog/dialog.component';

// Based on: https://darchuk.net/2019/01/11/angular-making-a-modal-service-by-extending-ngbmodal/
@Injectable({
  providedIn: 'root'
})
export class DialogService {
  lastErrorCloseResult: string;

  constructor(private modalService: NgbModal) { }

  public showMessage = (title: string, message: string, options: NgbModalOptions = null, allowLineBreaks: boolean = false): void => {
    var ref = this.modalService.open(DialogComponent, options);

    // Get the OkDialog from the NgbModal object  
    let dialog: DialogComponent = ref.componentInstance as DialogComponent;

    // Set the dialog's properties
    dialog.title = title;
    dialog.message = message;
    dialog.button1Text = "Close";
    dialog.button1Visible = true;
    dialog.button2Visible = false;
    dialog.allowLineBreaks = allowLineBreaks;
    dialog.close = (result) => {
      ref.close(result);
    };
    dialog.dismiss = (result) => {
      ref.dismiss(result);
    };

    ref.result.then((result) => {
      this.lastErrorCloseResult = `Closed with: ${result}`;
    }, (reason) => {
      this.lastErrorCloseResult = `Dismissed ${this.getDismissReason(reason)}`;
    })
  }

  public showError = (errorMessage: string, allowLineBreaks: boolean = false): void => {
    var ref = this.modalService.open(DialogComponent);

    // Get the OkDialog from the NgbModal object  
    let dialog: DialogComponent = ref.componentInstance as DialogComponent;

    // Set the dialog's properties
    dialog.title = "Error";
    dialog.message = errorMessage;
    dialog.button1Text = "Close";
    dialog.button1Visible = true;
    dialog.button2Visible = false;
    dialog.allowLineBreaks = allowLineBreaks;
    dialog.close = (result) => {
      ref.close(result);
    };
    dialog.dismiss = (result) => {
      ref.dismiss(result);
    };

    ref.result.then((result) => {
      this.lastErrorCloseResult = `Closed with: ${result}`;
    }, (reason) => {
      this.lastErrorCloseResult = `Dismissed ${this.getDismissReason(reason)}`;
    })
  }

  public showConfirmationExt = (title: string, confirmationMessage: string, options: NgbModalOptions = null, acceptCallback: () => void, rejectCallback?: () => void, allowLineBreaks: boolean = false): void => {
    var ref = this.modalService.open(DialogComponent, options);

    // Get the OkDialog from the NgbModal object  
    let dialog: DialogComponent = ref.componentInstance as DialogComponent;

    // Set the dialog's properties
    dialog.title = title;
    dialog.message = confirmationMessage;
    dialog.button1Text = "Accept";
    dialog.button1Visible = true;
    dialog.button2Text = "Reject";
    dialog.button2Visible = true;
    dialog.allowLineBreaks = allowLineBreaks;
    dialog.close = (result) => {
      ref.close(result);
    };
    dialog.dismiss = (result) => {
      ref.dismiss(result);
    };

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback();
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }

  public showConfirmation = (confirmationMessage: string, acceptCallback: () => void, rejectCallback?: () => void, allowLineBreaks: boolean = false): void => {
    var ref = this.modalService.open(DialogComponent);

    // Get the OkDialog from the NgbModal object  
    let dialog: DialogComponent = ref.componentInstance as DialogComponent;

    // Set the dialog's properties
    dialog.title = "Confirm"
    dialog.message = confirmationMessage;
    dialog.button1Text = "Accept";
    dialog.button1Visible = true;
    dialog.button2Text = "Reject";
    dialog.button2Visible = true;
    dialog.allowLineBreaks = allowLineBreaks;
    dialog.close = (result) => {
      ref.close(result);
    };
    dialog.dismiss = (result) => {
      ref.dismiss(result);
    };

    ref.result.then((result) => {
      switch (result) {
        case 1:
          acceptCallback();
          break;
        default:
          if (rejectCallback)
            rejectCallback();
      }
    }, (reason) => {
      if (rejectCallback)
        rejectCallback();
    })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
