<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon" [searchEnabled]="true" [openSearchDialogCallback]="showFilterDialog" [isFilterApplied]="isFilterApplied">
</app-page-title>
<div *ngIf="!usersService.hasSelectedCompanyModuleRight('Audiences', 'AudiencesList')">
    You have no rights to access this page
</div>
<div *ngIf="!!fullAudiences && usersService.hasSelectedCompanyModuleRight('Audiences', 'AudiencesList')">
    <mat-tab-group (selectedTabChange)="tabChange($event)">
        <mat-tab label="Active"></mat-tab>
        <mat-tab label="Archived" *ngIf="showArchivedTab"></mat-tab>
    </mat-tab-group>
    <app-publicis-table [dataSource]="audiencesDataSource" #parent>
        <ng-container *ngFor="let audience of parent.data; index as i">   
            <tr>
                <th scope="row" class="align-middle" style="width: 5%;">{{ audience.id }}</th>
                <td class="align-middle" style="width: 28%; word-break: break-all;">{{ audience.name }}</td>
                <td class="align-middle" style="width: 7%;">{{ audience.created | date }}</td>
                <td class="align-middle" style="width: 7%;">{{ audience.updated | date }}</td>
                <td class="align-middle" style="width: 5%;">{{ audience.profilesCount | number:'':'es' }}</td>
                <td class="align-middle" style="width: 16%;">
                    <ng-container *ngFor="let item of audience.idGraphCounts | keyvalue">
                        <div *ngIf="item.value > 0 && item.key != '6'">{{ audiencesService.getIdGraphTypeDescription(+item.key) }}: {{ item.value |
                            number:'':'es'}}</div>
                    </ng-container>
                    <br/>
                    <div *ngIf="!!audience.homesCount">Homes count: {{ audience.homesCount | number:'':'es' }}</div>
                </td>                
                <td class="align-middle" style="width: 4%;" *ngIf="usersService.hasSelectedCompanyActionRight('Audiences', 'AudiencesList', 'Manage')">
                    <div>
                        <div class="font-icon-wrapper-button" (click)="showAudienceDetails(audience)" style="cursor:pointer;display:inline-flex;width:44px;height:44px; ">
                            <i class="pe-7s-info"></i>
                            <i class="pe-7s-ticket" style="font-size:1.2em;position:relative;left:-10px;top:20px" *ngIf="!!audience.tags && audience.tags.length > 0"></i>
                        </div>
                    </div>
                </td>
                <td class="align-middle" style="width: 8%;">
                    <div>
                        <div class="font-icon-wrapper">
                            <i [class]="getStatusStyle(audience)"></i>
                        </div>
                        <div class="d-flex justify-content-center" style="text-align:center">
                            {{ getStatusLabel(audience) }}
                        </div>
                    </div>
                </td>
                <td class="align-middle" style="width: 6%;" *ngIf="usersService.hasSelectedCompanyActionRight('Audiences', 'AudiencesList', 'Manage')">
                    <div *ngIf="audience?.audienceFiles?.length > 0" 
                        (click)="toggleAudienceFilesVisibility(audience)"
                        class="btn-success badge badge-pill badge-warning"
                        style="padding: 0.4rem 0.6rem; margin-left: 0.5rem;">
                        {{audience?.audienceFiles?.length}} files
                        <i class="pe-7s-angle-up" *ngIf="filesVisibleAudienceId == audience.id" style="font-size: 1.5rem;vertical-align: middle;"></i>
                        <i class="pe-7s-angle-down" *ngIf="filesVisibleAudienceId != audience.id" style="font-size: 1.5rem;vertical-align: middle;"></i>            
                    </div>  
                </td>                 
                <td class="align-middle" style="width: 4%;" *ngIf="usersService.hasSelectedCompanyActionRight('Audiences', 'AudiencesList', 'Manage')">
                    <div *ngIf="audience?.status != 10">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" (menuOpened)="stopRefreshPolling()">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" (closed)="onMenuClosed($event)">
                            <button mat-menu-item [disabled]="audience?.status != 2 && audience?.status != 3" (click)="refreshAudience(audience)">
                                <mat-icon class="pe-7s-refresh-2"></mat-icon>
                                <span>Refresh</span>
                            </button>
                            <mat-divider></mat-divider>
                            <button mat-menu-item [disabled]="audience?.status != 2" (click)="calculateLookAlikeParameters(audience)" *ngIf="!audience.lookAlikeParametersCalculated">
                                <mat-icon class="pe-7s-power"></mat-icon>
                                <span>Calculate look-alike parameters</span>
                            </button>
                            <button mat-menu-item [disabled]="audience?.status != 2" (click)="showLookAlikes(audience)" *ngIf="audience.lookAlikeParametersCalculated">
                                <mat-icon class="pe-7s-users"></mat-icon>
                                <span>Show look alikes</span>
                            </button>
                            <mat-divider></mat-divider>
                            <button mat-menu-item [disabled]="audience?.status != 2 || !hasIdentifiers(audience)" (click)="sendAudience(audience)">
                                <mat-icon class="pe-7s-next-2"></mat-icon>
                                <span>Send audience</span>
                            </button>                       
                            <button mat-menu-item [disabled]="audience?.status != 2 || !hasIdentifiers(audience)" (click)="createAudienceFile(audience)">
                                <mat-icon class="pe-7s-note2"></mat-icon>
                                <span>Create file (no send)</span>
                            </button>    
                            <mat-divider></mat-divider>   
                            <button mat-menu-item [disabled]="audience?.status != 2" (click)="updateAudience(audience)">
                                <mat-icon class="pe-7s-pen"></mat-icon>
                                <span>Edit audience</span>
                            </button>                            
                            <button mat-menu-item [disabled]="audience?.status != 2" (click)="moveAudience(audience)">
                                <mat-icon class="pe-7s-repeat"></mat-icon>
                                <span>Move audience</span>
                            </button>       
                            <button mat-menu-item [disabled]="audience?.status != 2" (click)="deleteAudience(audience)">
                                <mat-icon class="pe-7s-trash"></mat-icon>
                                <span>Archive / Delete audience</span>
                            </button>
                            <button mat-menu-item [disabled]="audience?.status != 2" (click)="addComment(audience)">
                                <mat-icon class="pe-7s-note"></mat-icon>
                                <span>Add comment</span>
                            </button>                            
                            <button mat-menu-item [disabled]="audience?.status != 2" (click)="downloadRational(audience)">
                                <mat-icon class="pe-7s-magic-wand"></mat-icon>
                                <span>Generate rational</span>
                            </button>                            
                            <mat-divider></mat-divider>  
                            <button mat-menu-item [disabled]="audience?.status != 2" (click)="manageAudienceTags(audience)">
                                <mat-icon class="pe-7s-ticket"></mat-icon>
                                <span>Tags</span>
                            </button>                                                                                                   
                        </mat-menu>
                    </div>
                    <div *ngIf="audience?.status == 10">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="refreshAudience(audience)">
                                <mat-icon class="pe-7s-refresh-2"></mat-icon>
                                <span>Refresh</span>
                            </button>
                        </mat-menu>
                    </div>                    
                </td>               
            </tr>            
            <tr *ngIf="filesVisibleAudienceId == audience.id">
                <th scope="row" colspan="1"></th>
                <td colspan="10">
                    <table class="table table-striped">
                        <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col">Creation date</th>
                            <th scope="col">Attributes</th>
                            <th scope="col">Identifiers</th>
                            <th scope="col">Size</th>
                            <th scope="col">Times sent</th>
                            <th scope="col">Last sent date</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                        </tr>
                        <tr *ngFor="let audienceFile of audience.audienceFiles; index as j">
                            <th class="align-middle" style="width: 5%;">{{audienceFile.id}}</th>
                            <td class="align-middle" style="width: 5%;"><img width="32px" height="32px" [src]="getAudienceFileIcon(audienceFile)" /></td>
                            <td class="align-middle" style="width: 10%;">{{ audienceFile.created | date }}</td>
                            <td class="align-middle" style="width: 25%; word-break: break-all;">
                                <ng-container *ngFor="let attribute of audienceFile.customAttributes">
                                    {{ getFileAttributeDescription(attribute.attributeId, audienceFile.targetPlatform) }} : {{ attribute.value }}<br/>
                                </ng-container>
                            </td>
                            <td class="align-middle" style="width: 15%; word-break: break-all;">
                                <ng-container *ngFor="let idGraphType of audienceFile.idGraphTypes">
                                    {{ audiencesService.getIdGraphTypeDescription(idGraphType) }}<br/>
                                </ng-container>
                            </td>
                            <td class="align-middle" style="width: 7%;">{{ audienceFile.bytesSize | bytes }}</td>
                            <td class="align-middle" style="width: 3%;">{{ audienceFile.timesSent.length }}</td>
                            <td class="align-middle" style="width: 10%;">{{ audienceFile.timesSent.length > 0 ? (audienceFile.timesSent[audienceFile.timesSent.length - 1].timeSent | date:'medium') : '' }}</td>
                            <td class="align-middle" style="width: 5%;">
                                <div>
                                    <div class="font-icon-wrapper">
                                        <i [class]="getFileStatusStyle(audienceFile)"></i>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        {{ getFileStatusLabel(audienceFile) }}
                                    </div>
                                </div>
                            </td>
                            <td class="align-middle" style="width: 15%;">
                                <div class="btn" style="padding: 0.1rem 0.1rem" title="Download audience file">
                                    <div class="font-icon-wrapper-medium" (click)="downloadAudienceFile(audience, audienceFile)"
                                        *ngIf="audienceFile?.status == 5">
                                        <i class="pe-7s-download"></i>
                                    </div>
                                </div>
                                <div class="btn" style="padding: 0.1rem 0.1rem" title="Send audience file" *ngIf="isAudienceFileSendAllowed(audienceFile)">
                                    <div class="font-icon-wrapper-medium" (click)="sendAudienceFile(audience, audienceFile)">
                                        <i class="pe-7s-upload"></i>
                                    </div>
                                </div>                                
                                <div class="btn" style="padding: 0.1rem 0.1rem" title="Delete audience file" *ngIf="isAudienceFileDeletionAllowed(audienceFile)">
                                    <div class="font-icon-wrapper-medium" (click)="deleteAudienceFile(audience, audienceFile)">
                                        <i class="pe-7s-trash"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </ng-container>
    </app-publicis-table>
</div>