<div class="card">
    <div class="card-body">
        <ngx-spinner name="dialogSpinner" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p style="color: white">Loading ...</p>
        </ngx-spinner>
        <div class="row">
            <div class="col-md-12">
                <h3>Meta audience parameters</h3>
                <section>
                    <mat-button-toggle-group [(ngModel)]="newAudience" name="newAudienceSelector"
                        aria-label="New audience" (change)="onNewAudienceSelected()">
                        <mat-button-toggle value="0">Existing audience</mat-button-toggle>
                        <mat-button-toggle value="1">New audience</mat-button-toggle>
                    </mat-button-toggle-group>
                </section>
            </div>
        </div>
        <div class="row">&nbsp;</div>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-12">
                    <h3>Direct send</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <mat-form-field style="width: 100%;">
                        <mat-label>Business</mat-label>
                        <input #matAutocompleteBusinesses name="matAutocompleteBusinesses" type="text"
                            placeholder="Pick one" aria-label="Business" matInput
                            [formControl]="form.controls.businessId" [matAutocomplete]="auto"
                            (input)="filterBusinesses()" (focus)="filterBusinesses()" (click)="resetBusinessesFilter()">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            [displayWith]="getBusinessDescription.bind(this)"
                            (optionSelected)="onBusinessChange($event)">
                            <mat-option *ngFor="let business of filteredBusinesses"
                                [value]="business.id">{{business.name}}</mat-option>
                        </mat-autocomplete>
                        <mat-error
                            *ngIf="form.controls.businessId.errors && (form.controls.businessId.dirty || form.controls.businessId.touched)">Business
                            is required</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-9">
                    <mat-form-field *ngIf="adAccounts.length > 0" style="width: 100%;">
                        <mat-label>Ad Account</mat-label>
                        <input #matAutocompleteAdAccounts name="matAutocompleteAdAccounts" type="text"
                            placeholder="Pick one" aria-label="Add account" matInput
                            [formControl]="form.controls.adAccountId" [matAutocomplete]="auto"
                            (input)="filterAdAccounts()" (focus)="filterAdAccounts()" (click)="resetAdAccountsFilter()">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            [displayWith]="getAdAccountDescription.bind(this)"
                            (optionSelected)="onAdAccountChange($event)">
                            <mat-option *ngFor="let adAccount of filteredAdAccounts"
                                [value]="adAccount.accountId">{{adAccount.name}}</mat-option>
                        </mat-autocomplete>
                        <mat-error
                            *ngIf="form.controls.adAccountId.errors && (form.controls.adAccountId.dirty || form.controls.adAccountId.touched)">AdAccount
                            is required</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" *ngIf="newAudience == '0'">
                    <mat-form-field *ngIf="!!form.controls.adAccountId.value && audiences.length > 0"
                        style="width: 100%;">
                        <mat-label>Audience</mat-label>
                        <input #matAutocompleteAudiences name="matAutocompleteAudiences" type="text"
                            placeholder="Pick one" aria-label="Audience" matInput
                            [formControl]="form.controls.audienceId" [matAutocomplete]="auto"
                            (input)="filterAudiences()" (focus)="filterAudiences()" (click)="resetAudiencesFilter()"
                            style="width: 100%;">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            [displayWith]="getAudienceDescription.bind(this)"
                            (optionSelected)="onAudienceChange($event)">
                            <mat-option *ngFor="let audience of filteredAudiences" [value]="audience.id">{{audience.id}}
                                - {{audience.name}}</mat-option>
                        </mat-autocomplete>
                        <mat-error
                            *ngIf="form.controls.audienceId.errors && (form.controls.audienceId.dirty || form.controls.audienceId.touched)">Audience
                            is required</mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="!!form.controls.adAccountId.value && audiences.length == 0"
                        style="width: 100%;">The Ad Account has no audiences</mat-error>
                </div>
            </div>
        </form>
        <form [formGroup]="formShare" *ngIf="isSharingSectionVisible()">
            <div class="row">
                <div class="col-md-12">
                    <h3>Share with</h3>
                </div>
            </div>
            <div class="row">
                <div *ngFor="let sharedAccount of form.controls.sharedAccounts.value"
                    class="badge badge-pill badge-light"
                    style="padding: 0.4rem 0.6rem; margin-left: 0.5rem; margin-bottom: 0.5rem; cursor:pointer;">
                    <mat-icon class="pe-7s-share" style="vertical-align:middle;cursor:pointer;"></mat-icon>&nbsp;&nbsp;
                    <span style="font-size: 0.8rem;">{{getSharedAccountDescription(sharedAccount)}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <mat-icon class="pe-7s-trash" style="vertical-align:middle;color:red" (click)="removeSharedAccount(sharedAccount)" *ngIf="sharedAccount.isNew"></mat-icon>&nbsp;&nbsp;
                </div>
            </div>
            <div class="row">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <h3>Add new sharing</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h4>Visible accounts</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>Business</mat-label>
                                    <input #matAutocompleteBusinessesShare name="matAutocompleteBusinessesShare"
                                        type="text" placeholder="Pick one" aria-label="Business" matInput
                                        [formControl]="formShare.controls.businessShareId" [matAutocomplete]="auto"
                                        (input)="filterBusinessesShare()" (focus)="filterBusinessesShare()"
                                        (click)="resetBusinessesShareFilter()">
                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                        [displayWith]="getBusinessShareDescription.bind(this)"
                                        (optionSelected)="onBusinessShareChange($event)">
                                        <mat-option *ngFor="let businessShare of filteredBusinessesShare"
                                            [value]="businessShare.id">{{businessShare.name}}</mat-option>
                                    </mat-autocomplete>
                                    <mat-error
                                        *ngIf="formShare.controls.businessShareId.errors && (formShare.controls.businessShareId.dirty || formShare.controls.businessShareId.touched)">Business
                                        is required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-8">
                                <mat-form-field *ngIf="adAccountsShare.length > 0" style="width: 100%;">
                                    <mat-label>Ad Account</mat-label>
                                    <input #matAutocompleteAdAccountsShare name="matAutocompleteAdAccountsShare"
                                        type="text" placeholder="Pick one" aria-label="Ad account" matInput
                                        [formControl]="formShare.controls.adAccountShareId" [matAutocomplete]="auto"
                                        (input)="filterAdAccountsShare()" (focus)="filterAdAccountsShare()"
                                        (click)="resetAdAccountsShareFilter()">
                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                        [displayWith]="getAdAccountShareDescription.bind(this)">
                                        <mat-option *ngFor="let adAccountShare of filteredAdAccountsShare"
                                            [value]="adAccountShare.accountId">{{adAccountShare.name}}</mat-option>
                                    </mat-autocomplete>
                                    <mat-error
                                        *ngIf="formShare.controls.adAccountShareId.errors && (formShare.controls.adAccountShareId.dirty || formShare.controls.adAccountShareId.touched)">Ad
                                        Account is required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-1">
                                <mat-icon class="pe-7s-up-arrow"
                                    style="vertical-align:middle;color: green;cursor: pointer;" (click)="addSharing()"
                                    *ngIf="isAddSharingVisible()"></mat-icon>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5"></div>
                            <div class="col-md-2">
                                <h3>OR</h3>
                            </div>
                            <div class="col-md-5"></div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h4>Add by known ad account id</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-11">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>Ad Account known id</mat-label>
                                    <input type="text" placeholder="Pick one" aria-label="Ad account id" matInput
                                        [formControl]="formShare.controls.adAccountShareKnownId">
                                    <mat-error
                                        *ngIf="formShare.controls.adAccountShareKnownId.errors && (formShare.controls.adAccountShareKnownId.dirty || formShare.controls.adAccountShareKnownId.touched)">Ad
                                        Account id is required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-1">
                                <mat-icon class="pe-7s-up-arrow" style="vertical-align:middle;color: green;cursor: pointer;"
                                    (click)="addSharingKnownId()" *ngIf="isAddSharingKnownIdVisible()"></mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>