<div [class]="'app-header header-shadow ' + (config$ | async).headerTheme">
  <div class="logo-src"></div>
  <div class="app-header__content">
    <div>
      <!--<app-search-box></app-search-box>-->
      <div *ngIf="!!globalsService.getActiveCompany() && !globals.adminMode" class="page-title-heading" style="padding: 15px;">
        <img height="48px" [src]="companiesService.getCurrentCompanyLogoSrc()">
        <!--<span class="h5" style="padding: 25px;">{{globalsService.getActiveCompany().name}}</span>-->
        <button *ngIf="globals.backEnabled" type="button"
          class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="location.back()">
          {{globals.backLabel}}
        </button>
      </div>
      <div *ngIf="globals.adminMode" class="page-title-heading" style="padding: 15px;">
        <span class="h5" style="padding: 25px;">Admin section</span>
      </div>
    </div>
    <!--
    <div class="app-header-right">
      <!- Get current logged user doesn't fill adB2CId property ->
      <div *ngIf="impersonationService.isImpersonationActive() && !!globalsService.getLoggedUser().adB2CId" style="color: red;">(IMPERSONATED AS {{globalsService.getLoggedUser().name }}) </div>
      <app-user-box></app-user-box>
    </div>
      -->
  </div>
  <div class="app-header__mobile-menu">
    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
      [ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
  </div>
  <div class="app-header__menu">
    <span>
      <button class="btn-icon btn-icon-only btn btn-primary" [ngClass]="{'is-active' : globals.toggleHeaderMobile}"
        (click)="toggleHeaderMobile()">
        <div class="btn-icon-wrapper">
          <fa-icon [icon]="faEllipsisV"></fa-icon>
        </div>
      </button>
    </span>
  </div>
</div>