import { Component, OnDestroy, OnInit } from '@angular/core';
import { timer, forkJoin, Subscription } from 'rxjs';
import { PublicisTableDataSource } from 'src/app/models/components/publicis-table';
import { AudiencesFilesAttributes } from 'src/app/models/services/audiencesFilesAttributes.enum';
import { AudiencesFilesStatus } from 'src/app/models/services/audiencesFilesStatus.enum';
import { AudiencesStatus } from 'src/app/models/services/audiencesStatus.enum';
import { PublicisAudience, PublicisAudienceFile } from 'src/app/models/services/dataTransferInfo';
import { ErisManualProcessInfo } from 'src/app/models/services/erisManualProcessInfo';
import { ErisProcessQueueInfo } from 'src/app/models/services/erisProcessQueueInfo';
import { AdminService } from 'src/app/services/admin.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { DialogService } from 'src/app/services/core/dialog.service';
import { MonitorService } from 'src/app/services/monitor.service';
import { UsersService } from 'src/app/services/users.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-manual-processes',
  templateUrl: './manual-processes.component.html',
  styleUrls: ['./manual-processes.component.sass']
})
export class ManualProcessesComponent implements OnInit, OnDestroy {
  loadFinished: boolean = false;
  refreshTimerSubscription: Subscription;

  manualProcessesInfo: ErisManualProcessInfo[];
  attributesQueueProcessesInfo: ErisProcessQueueInfo[];
  audiencesDataSource: PublicisTableDataSource = {
    tableDefinition: [
      {
        headerDescription: 'ID',
        enableSort: true,
        sortFieldName: 'id'
      }, {
        headerDescription: 'Company',
        enableSort: true,
        sortFieldName: 'company.id'
      }, {
        headerDescription: 'Audience',
        enableSort: true,
        sortFieldName: 'name'
      }, {
        headerDescription: 'Created',
        enableSort: true,
        sortFieldName: 'created'      
      }, {
        headerDescription: 'Refreshed',
        enableSort: true,
        sortFieldName: 'updated'      
      }, {
        headerDescription: 'Matched profiles',
        enableSort: true,
        sortFieldName: 'profilesCount'      
      }, {
        headerDescription: '',
        enableSort: false      
      }, {
        headerDescription: 'Status',
        enableSort: true,
        sortFieldName: 'status',
        headerStyle: 'text-align: center;'      
      }, {
        headerDescription: '',
        enableSort: false      
      }, {
        headerDescription: '',
        enableSort: false      
      }     
    ],
    data: [],
    enableSort: true,
    enablePagination: true,
    pageSize: 10
  };
  public filesVisibleAudienceId?: number;  
  public audienceFileAttributesDefinition = ['', "Custom segment id", "Zip password", "Custom segment name", "External audience identifier", "External audience name", "External profile identifier", "External advertiser identifier", "External audience category name"];

  constructor(
    public usersService: UsersService,
    public companiesService: CompaniesService,
    private globals: ThemeOptions,
    private adminService: AdminService,
    private monitorService: MonitorService,
    private dialogService: DialogService
  ) { 
  }

  ngOnInit(): void {
    this.globals.adminMode = true;
    this.loadManualProcessesInfo();    
  }

  ngOnDestroy(): void {
    this.stopRefreshPolling();
    this.globals.adminMode = false;
  }

  loadManualProcessesInfo = (): void => {
    console.log("loadManualProcessesInfo start")
    let _this: ManualProcessesComponent = this;
    forkJoin([
      this.monitorService.getManualProcessesInfo(),
      this.monitorService.getRunningAndFailedAudiences()
    ]).subscribe({
      next(resp: [ErisManualProcessInfo[], PublicisAudience[]]) {
        _this.manualProcessesInfo = resp[0];
        let cloned: PublicisTableDataSource = { ..._this.audiencesDataSource};
        cloned.data = resp[1];
        _this.audiencesDataSource = cloned;
        _this.loadFinished = true;

        _this.stopRefreshPolling();
        _this.refreshTimerSubscription = timer(10000).subscribe(() => _this.loadManualProcessesInfo());

        console.log("loadManualProcessesInfo next")     
      },
      error(err) { 
        _this.stopRefreshPolling();   
        _this.refreshTimerSubscription = timer(10000).subscribe(() => _this.loadManualProcessesInfo());
        console.log("loadManualProcessesInfo error")     
      },
      complete() { 
      }
    });        
  }

  getStatusStyle = (audience: PublicisAudience): string => {
    switch (audience?.status) {
      case AudiencesStatus.Created:
        return "pe-7s-check";
      case AudiencesStatus.Error:
        return "pe-7s-attention";
      case AudiencesStatus.Archived:
        return "pe-7s-box1";  
      default:
        return "pe-7s-hourglass fa-pulse";
    };
  }

  getStatusLabel = (audience: PublicisAudience): string => {
    switch (audience?.status) {
      case AudiencesStatus.Created:
        return "Done";
      case AudiencesStatus.Error:
        return "Error";
      case AudiencesStatus.CalculatingProfilesCount:
        return "Calculating profiles count";
      case AudiencesStatus.CalculatingProfilesCountPerType:
        return "Calculating profiles count per type";
      case AudiencesStatus.RetrievingProfiles:
        return "Retrieving profiles";
      case AudiencesStatus.CalculatingLookAlikeParameters:
        return "Calculating look-alike parameters";        
      case AudiencesStatus.MovingAudience:
        return "Moving audience";          
      case AudiencesStatus.WaitingRetry:
        return "Processing issue, waiting for retry";            
      case AudiencesStatus.Archived:
        return "Archived";              
      default:
        return `Creating (${audience?.progressPercentage}%)`;
    };
  }

  getFileStatusStyle = (audienceFile: PublicisAudienceFile): string => {
    switch (audienceFile?.statusId) {
      case AudiencesFilesStatus.Creating:
      case AudiencesFilesStatus.Sending:
      case AudiencesFilesStatus.Sharing:
        return "pe-7s-hourglass fa-pulse";
      case AudiencesFilesStatus.CreationError:
      case AudiencesFilesStatus.SentError:
      case AudiencesFilesStatus.SharingError:
        return "pe-7s-attention";
      default:
        return "pe-7s-check";
    }    
  }

  getFileStatusLabel = (audienceFile: PublicisAudienceFile): string => {
    switch (audienceFile?.statusId) {
      case AudiencesFilesStatus.Creating:
        return `Creating (${audienceFile?.progress}%)`;
      case AudiencesFilesStatus.Sending:
        return `Sending (${audienceFile?.progress}%)`;
      case AudiencesFilesStatus.CreationError:
        return "Creation error";
      case AudiencesFilesStatus.SentError:
        return "Sent error";
      case AudiencesFilesStatus.Sharing:
        return "Sharing";
      case AudiencesFilesStatus.SharingError:
        return "Sharing error";
            default:
        return "Done";
    }
  }

  refreshAudience = (audience: PublicisAudience): void => {
    let _this: ManualProcessesComponent = this;
    this.stopRefreshPolling();
    this.dialogService.showConfirmation(
      `Are you sure to refresh "${audience.name}"? The look-alikes information related with the audience will be removed.`,
      () => {
        audience.status = AudiencesStatus.CalculatingProfilesCount;
        _this.adminService.refreshAudience(audience.id, audience.company.id).subscribe(
          () => {
            _this.dialogService.showMessage("Refresh audience", "Audience refresh process launched");
            _this.loadManualProcessesInfo();
          }, () => {
            _this.loadManualProcessesInfo();
          }, () => {
          });    
      },
      () => {
      });    
  }  

  deleteAudience = (audience: PublicisAudience): void => {
    let _this: ManualProcessesComponent = this;
    this.stopRefreshPolling();
    this.dialogService.showConfirmation(
      `Are you sure to remove "${audience.name}"?`,
      () => {
        _this.adminService.removeAudience(audience.id, audience.company.id).subscribe(
          () => {
            let index: number = _this.audiencesDataSource.data.findIndex(x => x.id == audience.id);
            let cloned: PublicisTableDataSource = { ..._this.audiencesDataSource};
            cloned.data.splice(index, 1);
            _this.audiencesDataSource = cloned;            
            _this.dialogService.showMessage("Remove audience", "Audience removed");
            _this.loadManualProcessesInfo();
          }, () => {
            _this.loadManualProcessesInfo();
          }, () => {
          });  
      }, 
      () => {},
      true
    );
  }  

  getAudienceFileIcon = (audienceFile: PublicisAudienceFile): string => {    
    return `assets/images/target-platforms/${audienceFile.targetPlatformId}.png`;
  }

  deleteAudienceFile = (audience: PublicisAudience, audienceFile: PublicisAudienceFile): void => {
    this.stopRefreshPolling();
    let customSegmentDescription: string = audienceFile.fileAttributes.find(x => x.attributeId == AudiencesFilesAttributes.CustomSegmentDescription)?.value;
    let confirmation: string = !!customSegmentDescription ? `Are you sure to remove file for custom segment "${customSegmentDescription}"?` : 'Are you sure to remove file?' 
    this.dialogService.showConfirmation(confirmation, 
      () => {
        this.adminService.removeAudienceFile(audience.id, audienceFile.id, audience.company.id).subscribe(
          () => {
            let audienceFileIndex: number = audience?.audiencesFiles?.findIndex(x => x.id == audienceFile.id);
            audience?.audiencesFiles?.splice(audienceFileIndex, 1);
            this.loadManualProcessesInfo();
          });
      },
      () => { 
        this.loadManualProcessesInfo();
      });
  }  

  stopRefreshPolling = (): void => {
    if (!!this.refreshTimerSubscription)
      this.refreshTimerSubscription.unsubscribe();
  }
}
