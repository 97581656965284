<div class="modal-header">    
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>    
</div>
<div class="modal-body">
    <div class="row" style="margin-bottom: 15px;">
        <div class="col-2" style="display: table;">
            <div style="display:table-cell; vertical-align:middle; text-align: right;">
                Show only attributes with at least
            </div>
        </div>
        <div class="col-1" style="display: table;">  
            <div style="display:table-cell; vertical-align:middle;text-align: center;">
                <mat-slider min="0" max="100000" step="100" thumbLabel [(ngModel)]="minimumProfilesNumber" (change)="refreshGlobal()">
                </mat-slider>    
                <input type="number" min="0" max="100000" step="100" [(ngModel)]="minimumProfilesNumber" (change)="refreshGlobal()">
            </div>
        </div>
        <div class="col-1" style="display: table;">
            <div style="display:table-cell; vertical-align:middle; text-align: left;">
                profiles
            </div>
        </div>
        <div class="col-1" style="display: table;">
            &nbsp;
        </div>        
        <div class="col-1" style="display: table;">
            <mat-checkbox [(ngModel)]="showProfilesCount" (change)="onShowCountsChange($event)">Show counts</mat-checkbox>
            <mat-radio-group *ngIf="showProfilesCount" [(ngModel)]="countType" (change)="onShowProfilesCountsChange($event)" aria-label="Select an option">
                <mat-radio-button value="1">Show profiles counts</mat-radio-button>
                <mat-radio-button value="2">Show identifiers counts</mat-radio-button>
            </mat-radio-group>
            <br *ngIf="countType == '2'"><br *ngIf="countType == '2'">
            <mat-form-field *ngIf="countType == '2'">
                <mat-label>Select an option</mat-label>
                <mat-select *ngIf="countType == '2'" [(ngModel)]="countIdentifierType">
                    <mat-option value="1">Hashed email</mat-option>
                    <mat-option value="4">MAID</mat-option>
                    <mat-option value="2">Weborama cookie</mat-option>
                    <mat-option value="5">Xandr cookie</mat-option>
                    <mat-option value="8">TTD cookie</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-1" style="display: table;">
            &nbsp;
        </div>
        <div class="col-1" style="display: table;">
            <mat-form-field >
                <mat-label>Select sort order</mat-label>
                <mat-select [(ngModel)]="sortType" (selectionChange)="onSortTypeChange()">
                    <mat-option value="1">Ascending by name (alphabetical)</mat-option>
                    <mat-option value="2">Descending by profile count</mat-option>
                </mat-select>
            </mat-form-field>                
        </div>
    </div>
    <div class="row" style="margin-bottom: 15px;">
        <div class="col-2" style="display: table;">
            <div style="display:table-cell; vertical-align:middle; text-align: right;">
                Global attributes search
            </div>
        </div>
        <div class="col-9" style="display: table;">
            <div style="display: table-cell; vertical-align: middle;">
                <app-search-box searchBoxName="globalFilter" [search]="searchGlobal"></app-search-box>
            </div>
        </div>
    </div>
    <div class="main-card mb-3 card" *ngIf="selectedTaxonomyItems.length > 0">
        <div class="card-body">
            <h3 class="card-title">Current rule selection</h3>
            <div *ngFor="let selectedTaxonomyItem of selectedTaxonomyItems; index as k"
                style="margin: 3px; cursor: default;"
                class="btn btn-secondary mt-2"
                [id]="'taxonomyItemSelected' + k">
                {{selectedTaxonomyItem.description}}                
                <div (click)="onTaxonomyItemClick(selectedTaxonomyItem.item)" class="btn btn-secondary mt-2">
                    <div class="font-icon-wrapper-medium"><i class="pe-7s-trash"></i></div>
                </div>    
            </div>
        </div>
    </div>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <div>
                <div class="row" >
                    <div class="col-md-6" style="display: table;">
                        <h3 class="card-title">
                            {{getNavigationPathString()}}
                            <button type="button" class="btn btn-secondary mt-2" (click)="onGoPrevious()" *ngIf="showPreviousButton()">
                                <div class="font-icon-wrapper-medium" ><i class="pe-7s-back"></i></div>
                            </button>
                        </h3>
                    </div>
                    <div class="col-md-2" style="display: table;" *ngIf="isCurrentSelectionSearchVisible()">
                        <div style="display:table-cell; vertical-align:middle; text-align: right;">
                            Search
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="isCurrentSelectionSearchVisible()">                            
                        <app-search-box searchBoxName="currentSelectionFilter" [search]="searchCurrentSelection"></app-search-box>
                    </div>
                </div>                                                            
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="onAccept()" *ngIf="showAcceptButton()">Accept</button>        
                <button type="button" class="btn btn-success" (click)="onDismiss()">Cancel</button>
            </div>            
            <div *ngFor="let taxonomyItem of currentFilteredTaxonomyItems; index as k"
                style="margin: 3px; cursor: default;"
                class="btn btn-secondary mt-2"
                [id]="'taxonomyItem' + k">
                {{taxonomyItem.name}}
                <div *ngIf="showProfilesCount && taxonomyItem.profilesCount > 0" class="ml-auto badge badge-pill badge-primary" style="margin-right: 5px;">{{getSelectedCount(taxonomyItem) | number:'':'es' }}</div>
                <div *ngIf="!taxonomyItem.isOnlyGroup" (click)="onTaxonomyItemClick(taxonomyItem)" [class]="isSelected(taxonomyItem) ? 'btn btn-success mt-2' : 'btn btn-secondary mt-2'">
                    <div class="font-icon-wrapper-medium" ><i class="pe-7s-check" ></i></div>
                </div>
                <div *ngIf="!!taxonomyItem.childs && taxonomyItem.childs.length > 0" class="btn btn-secondary mt-2" (click)="navigateInside(taxonomyItem)">
                    <div class="font-icon-wrapper-medium" ><i class="pe-7s-exapnd2"></i></div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="onAccept()" *ngIf="showAcceptButton()">Accept</button>        
                <button type="button" class="btn btn-success" (click)="onDismiss()">Cancel</button>
            </div>            
        </div>
    </div>
</div>