import { Inject, Injectable } from '@angular/core';
import { AccountInfo, SilentRequest, EndSessionRequest } from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { User } from '../models/services/user';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  private static _loggedUser: User;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService
  ) {

  }

  public isLogged = (): boolean => {
    let result: boolean = this.getLoggedAccount() != null;

    if (!result)
      this.deleteCookie('msal.interaction.status');

    return result;
  }

  public getLoggedAccount = (): AccountInfo => {
    let accounts: AccountInfo[] = this.authService.instance.getAllAccounts();
    let account: AccountInfo = accounts.length > 0 ? accounts[0] : null;

    if (!this.authService.instance.getActiveAccount())
      this.authService.instance.setActiveAccount(account);

    return account;
  }

  public login = (): void => {
    if (this.loginInteractionInProgress()) {
      timer(500).subscribe(() => this.login());
      return;
    }

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      /*
      this.authService.instance.addEventCallback((event) => {
        // set active account after redirect
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account;
          this.authService.instance.setActiveAccount(account);
        }
      });

      // handle auth redired/do all initial setup for msal
      this.authService.instance.handleRedirectPromise().then(authResult => {
        // Check if user signed in 
        if (!this.isLogged()) {
          if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
          } else {
            this.authService.loginRedirect();
          }
        }
      }).catch(err => {
        // TODO: Handle errors
        console.log(err);
      });*/

      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }

    }
  }

  public getCurrentAuthentication = (): Promise<AuthenticationResult> => {
    let request: SilentRequest = {
      //scopes: ['/'], // This is the key to get token from cache, because is the target searched by MSAL library
      scopes: environment.authScopes, // This is the key to get token from cache, because is the target searched by MSAL library
      forceRefresh: false

    };
    return this.authService.instance.acquireTokenSilent(request);
  }

  public logout = (): void => {    
    let request : EndSessionRequest = {
      account: this.getLoggedAccount(),
      postLogoutRedirectUri: environment.baseUri
    };
    this.authService.logoutPopup(request).subscribe(() => {
      console.info("Logout success");
    }, (x: any) => {
      console.error("Logout failure");
    });
  }

  public loginInteractionInProgress = (): boolean => {
    let status: string = sessionStorage.getItem('msal.interaction.status');

    console.log(`msal.interaction.status: ${status}`);

    return status == environment.clientId;
  }

  private deleteCookie = (name: string) : void => {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

}
