<div *ngIf="loadFinished && usersService.hasGlobalSectionRight('Admin')" style="position:relative;left:30px;width: 95%;">
    <div class="row">&nbsp;</div>
    <div style="text-align: right;">
        <button type="button" class="btn btn-success" (click)="refresh()">Refresh</button>
    </div>
    <div class="row">
        <h3>Running processes</h3>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Id</th>
                    <th scope="col">Type</th>
                    <th scope="col">Start time</th>
                    <th scope="col">Duration</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let processInfo of erisProcessesInfo.runningProcesses">
                    <td>
                        <div class="font-icon-wrapper-medium">
                            <i class="pe-7s-config fa-spin"></i>                       
                        </div>            
                    </td>
                    <td>{{processInfo.operationId}}</td>
                    <td>{{processInfo.name}}</td>
                    <td>{{processInfo.startTime  | timezoneDate:"d 'de' MMMM 'de' yyyy HH:mm:ss"}}</td>
                    <td>{{processInfo.processTimeInSeconds  | secondsToTime}}</td>                    
                    <td>
                        <div class="btn" style="padding: 0.1rem 0.1rem" title="Show process detail">
                            <div class="font-icon-wrapper" (click)="showProcessDetail(processInfo)">
                                <i class="pe-7s-info"></i>
                            </div> 
                        </div>    
                        <div class="btn" style="padding: 0.1rem 0.1rem" title="Mark process as idle">
                            <div class="font-icon-wrapper" (click)="markProcessAsIdle(processInfo)">
                                <i class="pe-7s-unlock"></i>
                            </div> 
                        </div>                                                                           
                    </td>                
                </tr>
            </tbody>
        </table>    
    </div>
    <br/>
    <br/>
    <div class="row">
        <h3>Finished processes</h3>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Type</th>
                    <th scope="col">Start time</th>
                    <th scope="col">End time</th>
                    <th scope="col">Details</th>
                    <th scope="col">Success</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let processInfo of erisProcessesInfo.finishedProcesses">
                    <td>{{processInfo.operationId}}</td>
                    <td>{{processInfo.name}}</td>
                    <td>{{processInfo.startTime | timezoneDate:"d 'de' MMMM 'de' yyyy HH:mm:ss"}}</td>
                    <td>{{processInfo.endTime | timezoneDate:"d 'de' MMMM 'de' yyyy HH:mm:ss"}}</td>
                    <td>
                        <div class="font-icon-wrapper" (click)="showProcessDetail(processInfo)">
                            <i class="pe-7s-info"></i>
                        </div>          
                    </td>                    
                    <td>
                        <div class="font-icon-wrapper-medium">
                            <i [ngClass]="processInfo.success ? 'pe-7s-check' : 'pe-7s-close-circle'" [style.color]="processInfo.success ? 'green' : 'red'"></i>                       
                        </div>            
                    </td>                    
                </tr>
            </tbody>
        </table>    
    </div>    
</div>