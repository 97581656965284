export enum AudiencesFilesAttributes {
    CustomSegmentId = 1,
    ZipPassword = 2,
    CustomSegmentDescription = 3,
    AudienceExternalIdentifier = 4,
    AudienceExternalName = 5,
    ProfileExternalIdentifier = 6,
    AdvertiserExternalIdentifier = 7,
    AudienceExternalCategoryName = 8,
    AudienceSharedAccounts = 9
}