<div class="card main-card mb-3">
    <div class="card-header">
        Ids distribution
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card no-shadow rm-border bg-transparent widget-chart text-center">
                    <div class="icon-wrapper rounded-circle">
                      <div class="icon-wrapper-bg opacity-9 bg-success"></div>
                      <i class="pe-7s-user text-white"></i>
                  </div>
                  <div class="widget-chart-content">
                      <div class="widget-subheading">Total number of profiles</div>
                      <div class="widget-numbers"><span>{{profileCounts.totalProfiles | number:'':'es' }}</span></div>
                    </div>
                </div>                
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-4 col-xl-4" *ngFor="let profileCount of profileCounts.counts; index as i">
                <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div class="icon-wrapper rounded-circle">
                    <div class="icon-wrapper-bg opacity-9 bg-success"></div>
                    <i class="pe-7s-user text-white"></i>
                </div>
                <div class="widget-chart-content">
                    <div class="widget-subheading">{{ profileCount.description }}</div>
                    <div class="widget-numbers"><span>{{ profileCount.count | number:'':'es' }}</span></div>
                    <div class="widget-content p-0">
                        <div class="widget-content-outer">
                          <div class="widget-content-wrapper">
                            <div class="widget-content-left pr-2">
                              <div class="widget-numbers fsize-1 text-success">{{(profileCount.count * 100 / profileCounts.totalIdGraphEntries).toFixed(2) | number:'':'es' }}%</div>
                            </div>
                            <div class="widget-content-right w-100">
                              <div class="progress-bar-xs progress">
                                <div class="progress-bar bg-success" role="progressbar" [attr.aria-valuenow]="(profileCount.count * 100 / profileCounts.totalIdGraphEntries).toFixed(2)" aria-valuemin="0"
                                     aria-valuemax="100" [style.width.%]="(profileCount.count * 100 / profileCounts.totalIdGraphEntries).toFixed(2)"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>