export enum AudiencesFilesTargetPlatforms {
    WAMProxy = 1,
    Meta = 2,
    TikTok = 3,
    UnityOnboarding = 4,
    TapTap = 5,
    Movistar = 6,
    Amazon = 7,
    TapTapOOH = 8,
    Xandr = 9,
    A3Media = 10,
    Mediaset = 11
}