import { Component, OnInit } from '@angular/core';
import { ProfileCount, ProfileCounts } from 'src/app/models/services/insights/insights';
import { InsightsService } from 'src/app/services/insights/insights.service';

@Component({
  selector: 'publicis-id-insights-idgraph-counts',
  templateUrl: './insights-idgraph-counts.component.html',
  styleUrls: ['./insights-idgraph-counts.component.sass']
})
export class InsightsIdgraphCountsComponent implements OnInit {
  public profileCounts: ProfileCounts = {
    totalProfiles: 0,
    totalIdGraphEntries: 0,
    counts: []
  };

  constructor(private insightsService: InsightsService) { }

  ngOnInit(): void {
    this.insightsService.getProfileCounts().subscribe(
      resp => {
        var profileCounts = {
          totalProfiles: resp.totalProfiles,
          totalIdGraphEntries: resp.totalIdGraphEntries,
          counts: []
        };
        var totalCookies = 0;
        resp.counts.forEach((element: ProfileCount) => {
          switch(element.description) {
            case "Hashed email":
            case "MAID":
              profileCounts.counts.push(element);
              break;
            default:
              totalCookies += element.count;            
          };
        });

        profileCounts.counts.push({ description: "Cookies", count: totalCookies } as ProfileCount);
        this.profileCounts = profileCounts;
      },
      () => {
      }
    )    
  }

}

